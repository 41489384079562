<template>
  <el-footer style="height: 220px; width: 100%; z-index:10">
    <div class="footer-container">
      <!-- 上部分 -->
      <div class="top">
        <div class="footer-logo">
          <img src="@/assets/bottomlogo.png" alt="Logo" class="logo" style="margin-top: 30px;">
        </div>
        <div class="content">
          <div class="content1">
            <div class="footertitle">商务合作</div>
            <div class="kefu">
              <img class="zuoji" src="@/assets/zuoji.png" alt="座机" />
              <span>（022）88919676</span>
            </div>
            <div>
              <img class="erweima" src="@/assets/hezuovx.png" alt="商务合作">
            </div>
          </div>
          <div class="content2">
            <div class="footertitle">官方客服</div>
            <div class="kefu">7*24小时客服线，添加客服加入答疑群。</div>
            <div>
              <img class="erweima" src="@/assets/kefuvx.png" alt="官方客服">
            </div>
          </div>
        </div>
      </div>
      <!-- 下部分 -->
      <div class="bottom">
        <div>©  2024-至今  chenfuzhizao.com.cn  版权所有  辰夫（天津）信息技术有限公司   天津市津南区海棠创业大街A座</div>
        <div style="padding-top: 2px">津ICP备案2021009976号</div>
      </div>
    </div>
  </el-footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer {
  background-color: #000;
  color: #fff;
 
  display: flex;
  

  /* bottom: 0px;
  position: fixed; */
  border-bottom: 1px solid #333;
  height: 300px;
  width: 100%;
 
}

.footer-container {
  width: 100%;
}
.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer-logo .logo {
  width: 70px;
  height: auto;
  margin-left: 40px;
}

.footer-contact, .footer-follow {
   margin-left: 90px;
   margin-top: -50px;
}

.qr-codes {
  display: flex;
}

.qr-code {
  text-align: center;
  margin-right: 20px;
}

.qr-code img {
  width: 100px;
  height: 100px;
}

.footer-bottom {
  text-align: center;
  padding-top: 40px;
  margin-left: 30px;
}
.content {
  width: 100%;
  display: flex;
  justify-content: end;
}
.content1 {
  margin-right: 100px;
  padding: 10px 30px;
}
.content2 {
  padding: 10px 30px;
}
.footertitle {
  font-size: 16px;
  line-height: 30px;
}
.kefu {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 5px;
}
.zuoji {
  height: 18px;
  width: 18px;
}
.erweima {
  width: 80px;
  height: auto;
}
.bottom {
  /* border-top: 1px solid rgba(255, 255, 255, 0.25); */
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
}
</style>
