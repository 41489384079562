
  export default {
    install(Vue, options) {
      let socket;
      Vue.prototype.$createSocket = () => {
        if (!socket) {
          socket = new WebSocket('ws://www.chenfuzhizao.com.cn/');
        }
        return socket;
      };
    },
  };