
import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles.css';
import VueMeta from 'vue-meta';
import socketPlugin from '@/services/socketPlugin';
Vue.use(VueMeta);
Vue.use(ElementUI);
Vue.use(socketPlugin);

import VueLazyload from 'vue-lazyload';

Vue.config.productionTip = false;
export const eventBus = new Vue();

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/error.png'),   // 引用 assets 中的错误图片
  loading: require('@/assets/loading.gif'), // 引用 assets 中的加载图片
  attempt: 1
});


new Vue({
  render: h => h(App),
}).$mount('#app');
    