<template>
  <div class="container" v-if="allResourcesLoaded">
    <el-dialog title="图片预览" :visible.sync="imgPreviewVisible" width="60%" :modal-append-to-body="true"
      class="previewdialog" style="text-align: center">
      <div style="height: 100%; width: 100%">
        <div ref="panzoomElement" class="previewdiv">
          <img :src="vpath1" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div
          style="width: 200px; display: flex; align-items: center; justify-content: space-around; border: 1px solid gray; border-radius: 5px 0 0 5px; line-height: 1; padding: 9px 0">
          <div style="display: flex; align-items: center; color: #000; cursor: pointer;" @click="lianbuxiufu">
            <img src="@/assets/icon11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>修脸</span>
          </div>
          <div style="display: flex; align-items: center; color: #000; cursor: pointer;" @click="kuotu">
            <img src="@/assets/icon22.png" style="height:20px; width:20px; margin-right:5px" />
            <span>扩图</span>
          </div>
        </div>
        <el-button type="primary" style="border-radius: 0 5px 5px 0;" icon="el-icon-download"
          @click="downloadImage">下载图片</el-button>
        <div class="sizeicon">
          <img src="@/assets/reset.png" style="height: 25px; width: 25px; cursor: pointer;" @click="resetZoom" />
          <i class="el-icon-minus" style="font-size: 25px; cursor: pointer;" @click="zoomOut"></i>
          <span>{{ scalePercent }}%</span>
          <i class="el-icon-plus" style="font-size: 25px; cursor: pointer;" @click="zoomIn"></i>
        </div>
      </span>
    </el-dialog>
    <div class="box" style="width: 120px;">
      <el-menu :default-active="currentMenu" @select="selectMenu" class="el-menu-vertical-demo">

        <el-menu-item index="1">
          <img src="@/assets/diaodai1.png" class="menuicon" />
          <span slot="title">AI换装</span>
        </el-menu-item>
        <el-menu-item index="2">
          <img src="@/assets/rentai1.png" class="menuicon" />
          <span slot="title">人台图</span>
        </el-menu-item>
        <el-menu-item index="3">
          <img src="@/assets/banlv1.png" class="menuicon" />
          <span slot="title">真人图</span>
        </el-menu-item>
        <el-menu-item index="4">
          <img src="@/assets/bao1.png" class="menuicon" />
          <span slot="title">商品图 </span>
        </el-menu-item>
        <el-menu-item index="5">
          <img src="@/assets/mote_1.png" class="menuicon" />
          <span slot="title">模特训练 </span>
        </el-menu-item>
        <el-menu-item index="6">
          <img src="@/assets/xie1.png" class="menuicon" />
          <span slot="title">鞋子上脚 </span>
        </el-menu-item>
        <el-menu-item index="7">
          <i class="el-icon-menu"></i>
          <span slot="title">脸部训练 </span>
        </el-menu-item>
        <el-menu-item index="8">
          <img src="@/assets/tongkuan.png" class="menuicon" style="max-width:120px" />
          <span slot="title">服饰改款 </span>
        </el-menu-item>
        <el-menu-item index="9">
          <img src="@/assets/081.png" class="menuicon" style="max-width:120px" />
          <span slot="title">文生款 </span>
        </el-menu-item>
        <el-menu-item index="10">
          <img src="@/assets/huaxing.png" class="menuicon" style="max-width:120px" />
          <span slot="title">花型衍生 </span>
        </el-menu-item>
        <el-menu-item index="11">
          <img src="@/assets/fengyi.png" class="menuicon" style="max-width:200px" />
          <span slot="title">线稿生款 </span>
        </el-menu-item>
      </el-menu>
    </div>
    <div v-if="currentMenu === '1'" class="box"
      style="width:290px; height:auto; padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="上下装衣服" name="first">
          <div
            style="position: relative;float: left;margin-left: 2.5%;width: 95%;border-radius: 10px;background: rgba(5, 5, 5, 0.06);border: 1px solid rgba(5, 5, 5, 0.06);height:auto;text-align: center;">
            <el-button type="primary" style="margin: 10px 0;" @click="upload1('0')">上传上装正面图<i
                class="el-icon-upload el-icon--right"></i></el-button>
            <div class="selpage1" v-if="shangmiantu != ''">
              <div class="box"
                style="position: relative;width:50%;background: none;border: none;text-align: center;float: left;margin-left: 0px;">
                <el-image style="width: 100px; height: 100px" :src="shangmiantu">
                </el-image>
                <p class="wenzi">原图</p>
              </div>
              <div class="box"
                style="position: relative;float: left;margin-left: 0px;width:50%;background: none;border: none;text-align: center;">
                <el-image style="width: 100px; height: 100px" :src="outputPath">
                </el-image>
                <p class="wenzi">保留区域</p>
              </div>

            </div>
            <!-- 四件上装案例 -->
            <!-- <div class="selpage1">
              <el-image style="width: 60px; height: 60px" :src="url1" @click="btn_shangshen('0')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="url2"
                @click="btn_shangshen('1')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="url3"
                @click="btn_shangshen('2')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="url4"
                @click="btn_shangshen('3')"></el-image>
            </div> -->
          </div>
          <div
            style="position: relative;float: left;margin-left: 2.5%;margin-top:30px;width: 95%;border-radius: 10px;background: rgba(5, 5, 5, 0.06);border: 1px solid rgba(5, 5, 5, 0.06);height:auto;text-align: center;">
            <el-button type="primary" style="margin: 10px 0;" @click="upload1('1')">上传下装正面图<i
                class="el-icon-upload el-icon--right"></i></el-button>
            <div class="selpage1" v-if="lshangmiantu != ''">
              <div class="box"
                style="position: relative;width:50%;background: none;border: none;text-align: center;float: left;margin-left: 0px;">
                <el-image style="width: 100px; height: 100px" :src="lshangmiantu">
                </el-image>
                <p class="wenzi">原图</p>
              </div>
              <div class="box"
                style="position: relative;float: left;margin-left: 0px;width:50%;background: none;border: none;text-align: center;">
                <el-image style="width: 100px; height: 100px" :src="loutputPath">
                </el-image>
                <p class="wenzi">保留区域</p>
              </div>

            </div>
            <!-- 四件下装案例 -->
            <!-- <div class="selpage1">
              <el-image style="width: 60px; height: 60px" :src="lurl1" @click="lbtn_shangshen('0')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lurl2"
                @click="lbtn_shangshen('1')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lurl3"
                @click="lbtn_shangshen('2')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lurl4"
                @click="lbtn_shangshen('3')"></el-image>
            </div> -->
          </div>

          <el-card class="box-card"
            style="width: 100%;position: relative;float: left;margin: 20px 0 50px 0;border: 1px solid rgba(5, 5, 5, 0.06);">
            <el-image style="max-width: 50px;margin-top: 10px;" :src="moteurl" @click="edit1"></el-image>
            <p style="color:#fb4747">已经选择的模特图片</p>
            <p></p>
            <div slot="header" class="clearfix" style="position: relative;margin-top: 10px;">
              <span>选择模特</span>

            </div>
            <div class="text item" @click="openbtn">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -5px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                选择模特库</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                系统提供将近10万名不同风格AI模特库</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div>

            <div class="text item" @click="openbtn1">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -10px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                上传模特库</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                您可以上传自己模特</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div>
            <!-- <div class="text item" @click="openbtn2">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -10px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                AI生成模特</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                通过提示词和标签生成您的模特</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div> -->
          </el-card>
          <!-- <el-button type="primary" plain @click="huianyi" style="position: relative;float: left;width: 100%;
           bottom: 20px;
          ">AI换装</el-button> -->
          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：10</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="huianyi">立即生成</el-button>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="连体衣服" name="second">
          <div
            style="position: relative;float: left;margin-left: 2.5%;width: 95%;border-radius: 10px;background: rgba(5, 5, 5, 0.06);border: 1px solid rgba(5, 5, 5, 0.06);height:auto;text-align: center;">
            <el-button type="primary" style="margin: 10px 0;" @click="upload1('0')">上传连体衣服正面图<i
                class="el-icon-upload el-icon--right"></i></el-button>
            <div class="selpage1" v-if="shangmiantu != ''">
              <div class="box"
                style="position: relative;width:50%;background: none;border: none;text-align: center;float: left;margin-left: 0px;">
                <el-image style="width: 100px; height: 100px" :src="shangmiantu">
                </el-image>
                <p class="wenzi">原图</p>
              </div>
              <div class="box"
                style="position: relative;float: left;margin-left: 0px;width:50%;background: none;border: none;text-align: center;">
                <el-image style="width: 100px; height: 100px" :src="outputPath">
                </el-image>
                <p class="wenzi">保留区域</p>
              </div>

            </div>
            <!-- 四件连体衣服案例 -->
            <!-- <div class="selpage1">
              <el-image style="width: 60px; height: 60px" :src="lturl1" @click="btn_shangshen1('0')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lturl2"
                @click="btn_shangshen1('1')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lturl3"
                @click="btn_shangshen1('2')"></el-image>
              <el-image style="width: 60px; height: 60px;margin-left: 10px;" :src="lturl4"
                @click="btn_shangshen1('3')"></el-image>
            </div> -->
          </div>
          <el-card class="box-card"
            style="width: 100%;position: relative;float: left;margin: 20px 0 50px 0;border: 1px solid rgba(5, 5, 5, 0.06);">
            <el-image style="max-width: 50px;margin-top: 30px;" :src="moteurl" @click="edit1"></el-image>
            <p style="color:#fb4747">已经选择的模特图片</p>
            <p></p>
            <div slot="header" class="clearfix" style="position: relative;margin-top: 10px;">
              <span>选择模特</span>

            </div>
            <div class="text item" @click="openbtn">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -5px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                选择模特库</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                系统提供将近10万名不同风格AI模特库</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div>
            <div class="text item" @click="openbtn1">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -5px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                上传模特库</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                您可以上传自己模特</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div>
            <!-- <div class="text item" @click="openbtn2">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -5px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                AI生成模特</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                通过提示词和标签生成您的模特</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div> -->
          </el-card>
          <!-- <el-button type="primary" plain @click="huianyi1" style="position: relative;float: left;width: 100%;
           bottom: 20px;
          ">AI换装</el-button> -->
          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：10</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="huianyi1">立即生成</el-button>
            </div>
          </template>
        </el-tab-pane>

      </el-tabs>
    </div>
    <!-- 人台图 -->
    <div v-if="currentMenu === '2'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center;">
          <el-upload class="upload_ele" drag :data="{ username: username }"
            action="http://www.chenfuzhizao.com.cn/upload1" list-type="picture" :file-list="fileList1"
            :on-success="(response, file, fileList) => handleUpload(response, file, fileList)"
            :on-preview="(file) => handleImgList(file)" :on-remove="(file, fileList) => handleRemoveImg(file, fileList)"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <!-- 模特 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">模特库</span>

            </div>
            <!-- 固定模特 -->
            <div>
              <!-- <span style="font-size: 12px; color: gray;">随机模特</span> -->
              <!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px"> -->
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList1[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <img v-lazy="item" @click="handleImage('1', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </img>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible">
                    <ImageMore v-if="imgMoreVisible" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList1" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('1')">
                      <el-image class="image" :src="imgList1[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
              <!-- </div> -->
            </div>
            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->

              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
                    <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('2')">
                      <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>

                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row> -->

            </div>
          </div>
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">发型</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList3[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <el-image :key="index" :src="item" @click="handleImage('3', item)"
                      :class="{ 'selected': saveFaxing === item }" class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible3">
                    <ImageMore v-if="imgMoreVisible3" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList3" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('3')">
                      <el-image class="image" :src="imgList3[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 表情 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">表情</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList4[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('4', item.url)">
                      <el-image :key="index" :src="item.url" :class="{ 'selected': saveBiaoqing === item.url }"
                        class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible4">
                    <ImageMore v-if="imgMoreVisible4" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList4" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('4')">
                      <el-image class="image" :src="imgList4[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 场景 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">场景</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList5[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('5', item.url)">
                      <el-image :key="index" :src="item.url" :class="{ 'selected': saveChangjing === item.url }"
                        class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible5">
                    <ImageMore v-if="imgMoreVisible5" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList5" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('5')">
                      <el-image class="image" :src="imgList5[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>

          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <!-- 真人图 -->
    <div v-if="currentMenu === '3'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center;">
          <el-upload class="upload_ele" drag :data="{ username: username }"
            action="http://www.chenfuzhizao.com.cn/upload1" list-type="picture" :file-list="fileList2"
            :on-success="(response, file, fileList) => handleUpload(response, file, fileList)"
            :on-preview="(file) => handleImgList(file)" :on-remove="(file, fileList) => handleRemoveImg(file, fileList)"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <!-- 模特 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">模特库</span>
              <!-- <span style="font-size: 12px; color: gray;">（固定模特与随机模特二选一）</span> -->
            </div>
            <!-- 固定模特 -->
            <div>
              <!-- <span style="font-size: 12px; color: gray;">随机模特</span> -->
              <!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px"> -->
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList1[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('1', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible">
                    <ImageMore v-if="imgMoreVisible" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList1" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('1')">
                      <el-image class="image" :src="imgList1[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
              <!-- </div> -->
            </div>
            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->
              <!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px"> -->
              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
                    <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('2')">
                      <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row> -->
              <!-- </div> -->
            </div>
          </div>
          <!-- 发型 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">发型</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList3[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <el-image :key="index" :src="item" @click="handleImage('3', item)"
                      :class="{ 'selected': saveFaxing === item }" class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible3">
                    <ImageMore v-if="imgMoreVisible3" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList3" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('3')">
                      <el-image class="image" :src="imgList3[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 表情 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">表情</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList4[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('4', item.url)">
                      <el-image :key="index" :src="item.url" :class="{ 'selected': saveBiaoqing === item.url }"
                        class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible4">
                    <ImageMore v-if="imgMoreVisible4" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList4" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('4')">
                      <el-image class="image" :src="imgList4[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 场景 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">场景</span>
            </div>
            <div>
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList5[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('5', item.url)">
                      <el-image :key="index" :src="item.url" :class="{ 'selected': saveChangjing === item.url }"
                        class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible5">
                    <ImageMore v-if="imgMoreVisible5" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList5" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('5')">
                      <el-image class="image" :src="imgList5[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 比例 -->
          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>
          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <!-- 商品图 -->
    <div v-if="currentMenu === '4'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center;">
          <el-upload class="upload_ele" drag :data="{ username: username }"
            action="http://www.chenfuzhizao.com.cn/upload1" list-type="picture" :file-list="fileList3"
            :on-success="(response, file, fileList) => handleUpload(response, file, fileList)"
            :on-preview="(file) => handleImgList(file)" :on-remove="(file, fileList) => handleRemoveImg(file, fileList)"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <!-- 场景 -->
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">场景</span>
              <span style="font-size: 12px; color: gray;">（固定场景与随机场景二选一）</span>
            </div>
            <!-- 固定场景 -->
            <div>
              <span style="font-size: 12px; color: gray;">固定场景</span>
              <el-row :gutter="15">
                <template v-for="(item, index) in cjImgList1[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('6', item.url)">
                      <el-image :src="item.url" :class="{ 'selected': saveChangjing === item.url }" class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible6">
                    <ImageMore v-if="imgMoreVisible6" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="cjImgList1" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('6')">
                      <el-image class="image" :src="cjImgList1[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
            <!-- 随机场景 -->
            <div>
              <div style="font-size: 12px; color: gray; margin: 8px 0 6px">随机场景</div>
              <el-row :gutter="15">
                <template v-for="(item, index) in cjImgList2[0]">
                  <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
                    <div class="image-wrapper" @click="handleImage('7', item.url)">
                      <el-image :src="item.url" :class="{ 'selected': saveChangjing === item.url }" class="image">
                      </el-image>
                      <div class="imgtext">{{ item.name }}</div>
                    </div>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible7">
                    <ImageMore v-if="imgMoreVisible7" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="cjImgList2" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('7')">
                      <el-image class="image" :src="cjImgList2[0][0].url" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 比例 -->
          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="prompts" type="textarea" :rows="2" style="width:260px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
          </div>
          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：2</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <!-- 假发图 -->
    <div v-if="currentMenu === '5'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center">
          地区:
          <el-select v-model="value" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          性别:
          <el-select v-model="value1" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          年龄:
          <el-select v-model="value2" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <br>
          笑容:
          <el-select v-model="value3" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="generatenum">
            <el-input v-model="prompts" type="textarea" :rows="2" style="width:260px;height:400px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
          </div>

          <div class="generate-footer">
            <div class="xiaohaodiv">
              <span>消耗：2</span>
              <img src="@/assets/jinbi.png" class="jinbi_icon" />
            </div>
            <el-button class="generatebtn" type="primary" @click="AIGenerate1">立即生成</el-button>
          </div>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '6'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center;">
          <el-upload class="upload_ele" drag :data="{ username: username }"
            action="http://www.chenfuzhizao.com.cn/upload1" list-type="picture" :file-list="fileList2"
            :on-success="(response, file, fileList) => handleUpload(response, file, fileList)"
            :on-preview="(file) => handleImgList(file)" :on-remove="(file, fileList) => handleRemoveImg(file, fileList)"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <div style="text-align: left;">
            请直接上传鞋子的功能，目前AI换鞋没有增加抠图功能
          </div>
          <!-- 模特 -->
          <div style="text-align: left;">
            <el-image style="max-width: 50px;margin-top: 10px;" :src="moteurl" @click="edit1"></el-image>
            <p style="color:#fb4747">已经选择的模特图片</p>
            <p></p>
            <!-- 固定模特 -->
            <div class="text item" @click="openbtn1">
              <img src="@/assets/xianshi.png" style="max-width:60px;margin-top: -10px">
              <span
                style="top: -70px;position: relative; margin-left: 10px;font-size: 16px;color: black;font-weight: bolder;">
                上传模特库</span>
              <p
                style="top: -70px;position: relative; margin-left: 75px;font-size: 13px;color: #666666;font-weight: bolder;">
                您可以上传自己模特</p>
              <i class="el-icon-arrow-right" style="font-size: 33px;float: right;margin-top: -100px;"></i>
            </div>

            <div>

              <!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px"> -->
              <el-row :gutter="15">
                <template v-for="(item, index) in imgList1[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('1', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
                <el-col :span="8" class="imgcol">
                  <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible">
                    <ImageMore v-if="imgMoreVisible" @close="handleCloseMore" :selected="currentSelected"
                      @childSelected="childSelected" :imgList="imgList1" :title="moreTitle" />
                    <div class="more" slot="reference" @click="handleMore('1')">
                      <el-image class="image" :src="imgList1[0][0]" style="filter: blur(5px);"></el-image>
                      <div class="more-text">
                        <span>更多</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
              <!-- </div> -->
            </div>
            <!-- 随机模特 -->

          </div>
          <!-- 发型 -->

          <!-- 表情 -->
          <div style="text-align: left;">

            <div>

            </div>
          </div>
          <!-- 场景 -->
          <div style="text-align: left;">

            <div>

            </div>
          </div>
          <!-- 比例 -->
          <div style="text-align: left; margin-bottom: 10px">

            <div>

            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>
          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：2</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '7'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <div style="text-align: center">
          地区:
          <el-select v-model="value" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          性别:
          <el-select v-model="value1" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          年龄:
          <el-select v-model="value2" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <br>
          笑容:
          <el-select v-model="value3" placeholder="请选择" style="width: 230px;">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="generatenum">
            <el-input v-model="prompts" type="textarea" :rows="2" style="width:260px;height:400px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
          </div>

          <div class="generate-footer">
            <div class="xiaohaodiv">
              <span>消耗：2</span>
              <img src="@/assets/jinbi.png" class="jinbi_icon" />
            </div>
            <el-button class="generatebtn" type="primary" @click="AIGenerate2">立即生成</el-button>
          </div>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '8'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <span style="font-size: 14px;">上传款式图</span>
        <div style="text-align: center;maring-top: 10px">

          <el-upload class="upload_ele" drag list-type="picture" :on-change="handleFileChange">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <img :src="base64Image" class="upload_img" v-if="base64Image != ''"
            style="max-width: 100%; max-height: 100%; margin-top: 10px;" />
          <!-- 模特 -->

          <span style="font-size: 14px;float: left;margin-top: 10px">原图相似度</span>
          <el-slider v-model="tvalue" :min='0' :max="1" :step="0.1" style="margin-top: 20px;position: relative"
            show-input>
          </el-slider>
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">输入提示词</span>

            </div>
            <el-input v-model="prompts" type="textarea" :rows="5" style="width:260px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
            <el-card class="box-card" style="margin-top: 20px;">
              <div slot="header" class="clearfix">
                <span>选择标签</span>
              </div>
              <el-popover placement="top-start" title="颜色选择" width="500" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="yanse(index)"
                  v-for="(item, index) in colorlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  颜色 <span style="color: red;font-size: 12px;">{{ mycolor }}</span>
                </div>
              </el-popover>

              <el-popover placement="top-start" title="单品品类" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="danpin(index)"
                  v-for="(item, index) in kuanxinglist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  单品品类 <span style="color: red;font-size: 12px;">{{ mydanpin }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="性别" width="400" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="sex(index)"
                  v-for="(item, index) in sexlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  性别 <span style="color: red;font-size: 12px;">{{ mysex }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="面辅料" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="mianliao(index)"
                  v-for="(item, index) in mianliaolist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  面辅料 <span style="color: red;font-size: 12px;">{{ mymianliao }}</span>
                </div>
              </el-popover>
            </el-card>

            <!-- 固定模特 -->

            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->

              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
  <el-col :span="8" class="imgcol">
    <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
      <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
        @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
      <div class="more" slot="reference" @click="handleMore('2')">
        <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>

        <div class="more-text">
          <span>更多</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </el-popover>
  </el-col>
  </el-row> -->

            </div>
          </div>

          <!-- 表情 -->

          <!-- 场景 -->

          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>

          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate3">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '9'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
       
        <div style="text-align: center;maring-top: 10px">

          
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">输入提示词</span>

            </div>
            <el-input v-model="prompts" type="textarea" :rows="5" style="width:260px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
            <el-card class="box-card" style="margin-top: 20px;">
              <div slot="header" class="clearfix">
                <span>选择标签</span>
              </div>
              <el-popover placement="top-start" title="颜色选择" width="500" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="yanse(index)"
                  v-for="(item, index) in colorlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  颜色 <span style="color: red;font-size: 12px;">{{ mycolor }}</span>
                </div>
              </el-popover>

              <el-popover placement="top-start" title="单品品类" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="danpin(index)"
                  v-for="(item, index) in kuanxinglist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  单品品类 <span style="color: red;font-size: 12px;">{{ mydanpin }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="性别" width="400" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="sex(index)"
                  v-for="(item, index) in sexlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  性别 <span style="color: red;font-size: 12px;">{{ mysex }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="面辅料" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="mianliao(index)"
                  v-for="(item, index) in mianliaolist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  面辅料 <span style="color: red;font-size: 12px;">{{ mymianliao }}</span>
                </div>
              </el-popover>
            </el-card>

            <!-- 固定模特 -->

            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->

              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
  <el-col :span="8" class="imgcol">
    <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
      <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
        @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
      <div class="more" slot="reference" @click="handleMore('2')">
        <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>

        <div class="more-text">
          <span>更多</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </el-popover>
  </el-col>
  </el-row> -->

            </div>
          </div>

          <!-- 表情 -->

          <!-- 场景 -->

          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>

          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate4">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '10'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <span style="font-size: 14px;">上传款式图</span>
        <div style="text-align: center;maring-top: 10px">

          <el-upload class="upload_ele" drag list-type="picture" :on-change="handleFileChange">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <img :src="base64Image" class="upload_img" v-if="base64Image != ''"
            style="max-width: 100%; max-height: 100%; margin-top: 10px;" />
          <!-- 模特 -->

          <span style="font-size: 14px;float: left;margin-top: 10px">原图相似度</span>
          <el-slider v-model="tvalue" :min='0' :max="1" :step="0.1" style="margin-top: 20px;position: relative"
            show-input>
          </el-slider>
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">输入提示词</span>

            </div>
            <el-input v-model="prompts" type="textarea" :rows="5" style="width:260px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
           

            <!-- 固定模特 -->

            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->

              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
  <el-col :span="8" class="imgcol">
    <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
      <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
        @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
      <div class="more" slot="reference" @click="handleMore('2')">
        <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>

        <div class="more-text">
          <span>更多</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </el-popover>
  </el-col>
  </el-row> -->

            </div>
          </div>

          <!-- 表情 -->

          <!-- 场景 -->

          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>

          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate5">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="currentMenu === '11'" class="box"
      style="width: 290px;height:auto;padding: 20px 14px 0px 20px; overflow-y: auto; scrollbar-width: thin;">
      <template>
        <span style="font-size: 14px;">上传线稿图</span>
        <div style="text-align: center;maring-top: 10px">

          <el-upload class="upload_ele" drag list-type="picture" :on-change="handleFileChange">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <img :src="base64Image" class="upload_img" v-if="base64Image != ''"
            style="max-width: 100%; max-height: 100%; margin-top: 10px;" />
          <!-- 模特 -->

          <span style="font-size: 14px;float: left;margin-top: 10px">原图相似度</span>
          <el-slider v-model="tvalue" :min='0' :max="1" :step="0.1" style="margin-top: 20px;position: relative"
            show-input>
          </el-slider>
          <div style="text-align: left;">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">输入提示词</span>

            </div>
            <el-input v-model="prompts" type="textarea" :rows="5" style="width:260px;margin-left: 20px"
              placeholder="请输入增强提示词">

            </el-input>
            <el-card class="box-card" style="margin-top: 20px;">
              <div slot="header" class="clearfix">
                <span>选择标签</span>
              </div>
              <el-popover placement="top-start" title="颜色选择" width="500" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="yanse(index)"
                  v-for="(item, index) in colorlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  颜色 <span style="color: red;font-size: 12px;">{{ mycolor }}</span>
                </div>
              </el-popover>

              <el-popover placement="top-start" title="单品品类" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="danpin(index)"
                  v-for="(item, index) in kuanxinglist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  单品品类 <span style="color: red;font-size: 12px;">{{ mydanpin }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="性别" width="400" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="sex(index)"
                  v-for="(item, index) in sexlist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  性别 <span style="color: red;font-size: 12px;">{{ mysex }}</span>
                </div>
              </el-popover>
              <el-popover placement="top-start" title="面辅料" width="800" trigger="hover" content="">


                <el-button plain style="position:relative;float:left;margin-left: 10px" @click="mianliao(index)"
                  v-for="(item, index) in mianliaolist" :key="index">{{ item }}</el-button>

                <div class="text item" style="height:40px;line-height:40px;" slot="reference">
                  面辅料 <span style="color: red;font-size: 12px;">{{ mymianliao }}</span>
                </div>
              </el-popover>
            </el-card>

            <!-- 固定模特 -->

            <!-- 随机模特 -->
            <div>
              <!-- <div style="font-size: 12px; color: gray; margin: 8px 0 6px">固定模特</div> -->

              <!-- <el-row :gutter="15">
                <template v-for="(item, index) in imgList2[0]">
                  <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
                    <el-image :src="item" @click="handleImage('2', item)" :class="{ 'selected': saveMote === item }"
                      class="image">
                    </el-image>
                  </el-col>
                </template>
  <el-col :span="8" class="imgcol">
    <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible2">
      <ImageMore v-if="imgMoreVisible2" @close="handleCloseMore" :selected="currentSelected"
        @childSelected="childSelected" :imgList="imgList2" :title="moreTitle" />
      <div class="more" slot="reference" @click="handleMore('2')">
        <el-image class="image" :src="imgList2[0][0]" style="filter: blur(5px);"></el-image>

        <div class="more-text">
          <span>更多</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </el-popover>
  </el-col>
  </el-row> -->

            </div>
          </div>

          <!-- 表情 -->

          <!-- 场景 -->

          <div style="text-align: left; margin-bottom: 10px">
            <div style="display: flex; align-items: center; margin: 10px 0;">
              <span style="font-size: 14px;">比例</span>
            </div>
            <div>
              <el-row :gutter="15">
                <el-radio-group v-model="radio" size="small">
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '比例不变' }"
                      @click="selectRadio('1:1')">比例不变</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                      @click="selectRadio('1:1')">1:1</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                      @click="selectRadio('3:4')">3:4</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                      @click="selectRadio('16:9')">16:9</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                      @click="selectRadio('9:16')">9:16</el-button>
                  </el-col>
                </el-radio-group>
              </el-row>
            </div>
          </div>
          <div class="generatenum">
            <el-input v-model="num" type="number" :min="1" :max="4" disabled>
              <template slot="prepend">生成数量</template>
            </el-input>
          </div>

          <template>
            <div class="generate-footer">
              <div class="xiaohaodiv">
                <span>消耗：4</span>
                <img src="@/assets/jinbi.png" class="jinbi_icon" />
              </div>
              <el-button class="generatebtn" type="primary" @click="AIGenerate6">立即生成</el-button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="box" style="flex-grow: 1;text-align: center; background-color: #eef1f7">

      <!-- 人台图... 右侧部分 -->
      <template>
        <div @click="handleCloseMore">
          <template>
            <!-- 左 -->
            <div class="xiaoguodiv">
              <div class="xiaoguotu">
                <!-- 加载图标 -->
                <div class="loading_overlay" v-if="isLoadingXiaoguo">
                  <div>
                    <i class="el-icon-loading"></i>
                    <div style="margin-top: 5px">{{ loadingText }}</div>
                  </div>
                </div>

                <div v-show="uploadUrl === '' && vpath1 === ''" class="image_container">
                  <img :src="miaoshuUrl" style="width: 240px; height: auto" />
                  <div v-show="miaoshuUrl && miaoshuUrl.includes('xiaoguobg')">高品质的图片才可以生成高品质的模特哦</div>
                </div>
                <br>

                <div class="image-container" style="margin-left:10px">
                  <img v-lazy="item.outpath" v-for="(item, index) in koutulist" :key="index" style="max-width:80px" />

                  <!-- 更多图片 -->
                </div>
                <!-- 生成后的效果 -->
                <div class="image_container">
                  <img style="max-width: 400px; border-radius: 10px" v-lazy="vpath1" v-if="vpath1 != ''" />
                  <div class="overlay">
                    <i />
                    <i class="el-icon-zoom-in" @click="zoomImage"
                      style="font-size: 30px; cursor: pointer; color: #fff;"></i>
                    <div class="botton_icon">
                      <img src="@/assets/icon1.png" v-if=" currentMenu <= '7'" style="height:30px; width: 30px; margin-right: 3px"
                        @click="lianbuxiufu" />
                      <img src="@/assets/icon2.png" style="height:30px; width: 30px; margin: 0 15px" @click="kuotu" />

                      <img src="@/assets/rentai.png" v-if="currentMenu == '7'"
                        style="height:30px; width: 30px; margin: 0 15px" @click="gotorentai" />
                      <img src="@/assets/zhenren.png" v-if="currentMenu == '7'"
                        style="height:30px; width: 30px; margin: 0 15px" @click="gotozhenrentu" />
                      <img src="@/assets/huanzhuang.png" v-if="currentMenu == '5'"
                        style="height:30px; width: 30px; margin: 0 15px" @click="gotohuanzhuang" />
                      <i class="el-icon-download" @click="downloadImage"
                        style="font-size: 30px; cursor: pointer; color: #fff"></i>
                    </div>
                  </div>
                </div>
                <br>
                <!-- <p><el-button @click="downloadImage" v-if="vpath1 != ''" style="margin-left:10px">下载换装结果图</el-button></p> -->
                <div class="showImgdiv" v-show="uploadUrl !== '' && vpath !== ''">
                  <img class="img" :src="uploadUrl" alt="" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" width="800px" height="900px"
      :before-close="handleClose">
      <el-tabs v-model="activeName1" @tab-click="handleClick1">
        <el-tab-pane :label="mytext" name="first">
          <el-upload class="upload-demo" style="max-width: 100%;" drag action="http://www.chenfuzhizao.com.cn/upload"
            :data="{ username: username }" :on-success="chengong">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">图片要求格式要求：支持jpg、jpeg、png、avif、webp格式，10M以内，最小：384*384，最大：4096*4096

              拍摄要求：正面、清晰平整、竖立、无遮挡的平铺服装图；不支持侧面、背面、带人物的或重叠的服装图</div>
            <div class="el-upload__tip" slot="tip">样例：

              <el-image style="width: 60px; height: 60px" :src="furl1"></el-image>
            </div>
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="传过的图" name="second">

          <div class="model-gallery">
            <div v-for="(model, index) in uploads" :key="model.filePath" class="model-card" @click="selectModel(index)">
              <img v-lazy="model.filePath">
              <i class="el-icon-check" style="color: green;font-size: 60px;" v-if="model.selected == '1'"></i>
            </div>
            <div ref="loadMore" class="loader">
              <el-spinner v-if="loading" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
    <el-dialog :title="dialogtitle1" :visible.sync="dialogVisible1" width="1200px" height="1000px">
      <div id="scrollContainerDialog" style="height: 900px; overflow-y: scroll;">
        <!-- <el-tabs v-model="activeName3" @tab-click="handleClick3">
          <el-tab-pane label="全部" name="first0"></el-tab-pane>
          <el-tab-pane label="亚洲男模" name="first1"></el-tab-pane>
          <el-tab-pane label="亚洲女模" name="first2"></el-tab-pane>
          <el-tab-pane label="美洲男模" name="first3"></el-tab-pane>
          <el-tab-pane label="美洲女模" name="first4"></el-tab-pane>
          <el-tab-pane label="欧洲男模" name="first5"></el-tab-pane>
          <el-tab-pane label="欧洲女模" name="first6"></el-tab-pane>
          <el-tab-pane label="非洲男模" name="first7"></el-tab-pane>
          <el-tab-pane label="非洲女模" name="first8"></el-tab-pane>
          <el-tab-pane label="东南亚男模" name="first9"></el-tab-pane>
          <el-tab-pane label="东南亚女模" name="first10"></el-tab-pane>
        </el-tabs> -->
        <div class="model-gallery">
          <div v-for="(model, index) in models" :key="model.id" class="model-card" @click="selectModel1(index)"><img
              v-lazy="model.image_path" :alt="model.name">
            <i class="el-icon-check" style="color: green;font-size: 60px;" v-if="model.selected == '1'"></i>
          </div>
          <div class="loader"><el-spinner v-if="loading" /></div>
          <div style="text-align: center; margin: 20px 0;">
            <el-button type="primary" @click="loadMoreModels">显示更多</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 模特上传部分 -->
    <el-dialog title="模特上传" :visible.sync="dialogVisible2" width="800px" height="900px" :before-close="handleClose2">
      <el-tabs v-model="activeName1" @tab-click="ehandleClick1">
        <el-tab-pane :label="mytext" name="first">
          <el-upload class="upload-demo" style="max-width: 100%;" drag action="http://www.chenfuzhizao.com.cn/upload3"
            :data="{ username: username }" :on-success="chengong1">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">图片要求格式要求：支持jpg、jpeg、png、avif、webp格式，10M以内，最小：384*384，最大：4096*4096

              拍摄要求：正面、清晰平整、竖立、无遮挡的模特图；</div>
            <div class="el-upload__tip" slot="tip">样例：

              <el-image style="width: 60px; height: 60px" :src="yurl1"></el-image>
            </div>
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="自己的模特库" name="second">

          <div class="model-gallery">
            <div v-for="(model, index) in uploads" :key="model.filePath" class="model-card" @click="selectMode2(index)">
              <img v-lazy="model.filePath">
              <i class="el-icon-check" style="color: green;font-size: 60px;" v-if="model.selected == '1'"></i>
            </div>
            <div ref="loadMore" class="loader">
              <el-spinner v-if="loading" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
    <el-dialog title="自定义生成模特" :visible.sync="dialogVisible3" width="800px" height="900px" :before-close="handleClose3">
      地区:
      <el-select v-model="value" placeholder="请选择" style="width: 20%;">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      性别:
      <el-select v-model="value1" placeholder="请选择" style="width: 20%;">
        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      年龄范围:
      <el-select v-model="value2" placeholder="请选择" style="width: 20%;">
        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <br>
      笑容:
      <el-select v-model="value3" placeholder="请选择" style="width: 20%;">
        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <img :src="dpath" style="max-width: 90px;" @click="edit2">
      <el-button type="primary" plain @click="generateModel">生成模特</el-button>&nbsp;
      <el-button type="primary" plain @click="queren">确认选择</el-button>
    </el-dialog>
    <!--  -->
    <el-dialog title="图片预览" :visible.sync="dialogVisible4" width="800px" height="900px">
      <img :src="editurl" style="max-width: 100%;">
    </el-dialog>

    <PricingModal :visible.sync="showModal" />

  </div>
</template>

<script>
import Panzoom from '@panzoom/panzoom';
import apiClient from '@/services/api'; // 假设你有一个 apiClient 处理请求
import axios from 'axios';
import PricingModal from './PricingModal.vue';
import ImageMore from './ImageMore.vue';
import outputPathImage from '@/assets/loading1.gif';
import loutputPathImage from '@/assets/loading1.gif';
import mote1 from '../assets/mote4.jpg';
import mote2 from '../assets/mote3.jpg';
import mote3 from '../assets/mote5.jpg';
import mote4 from '../assets/mote6.jpg';
import mote5 from '../assets/mote7.jpg';
import mote6 from '../assets/mote8.jpg';
import mote7 from '../assets/mote9.png';
import motee1 from '../assets/motee1.jpg';
import motee2 from '../assets/motee2.jpg';
import motee3 from '../assets/motee3.jpg';
import motee4 from '../assets/motee4.jpg';
import motee5 from '../assets/motee5.jpg';
import motee6 from '../assets/motee6.jpg';
import motee7 from '../assets/motee7.jpg';
import faxing1 from '../assets/faxing1.jpg';
import faxing2 from '../assets/faxing2.jpg';
import faxing3 from '../assets/faxing3.jpg';
import faxing4 from '../assets/faxing4.jpg';
import faxing5 from '../assets/faxing5.jpg';
import faxing6 from '../assets/faxing6.jpg';
import faxing7 from '../assets/faxing7.jpg';
import faxing8 from '../assets/faxing8.jpg';
import faxing9 from '../assets/faxing9.jpg';
import faxing10 from '../assets/faxing10.png';
import faxing11 from '../assets/faxing11.png';
import faxing12 from '../assets/faxing12.png';
import faxing13 from '../assets/faxing13.png';
import faxing14 from '../assets/faxing14.png';
import faxing15 from '../assets/faxing15.png';
import biaoqing1 from '../assets/biaoqing1.jpg';
import biaoqing2 from '../assets/biaoqing2.jpg';
import biaoqing3 from '../assets/biaoqing3.jpg';
import biaoqing4 from '../assets/biaoqing4.jpg';
import biaoqing5 from '../assets/biaoqing5.jpg';
import biaoqing6 from '../assets/biaoqing6.jpg';
import biaoqing7 from '../assets/biaoqing7.jpg';
import biaoqing8 from '../assets/biaoqing8.jpg';

import changjing1 from '../assets/changjing1.jpg';
import changjing2 from '../assets/changjing2.jpg';
import changjing3 from '../assets/changjing3.jpg';
import changjing4 from '../assets/changjing4.jpg';
import changjing5 from '../assets/changjing5.jpg';
import changjing6 from '../assets/changjing6.jpg';
import changjing7 from '../assets/changjing7.jpg';
import cj11 from '../assets/cj11.jpg';
import cj12 from '../assets/cj12.jpg';
import cj13 from '../assets/cj13.jpg';
import cj14 from '../assets/cj14.jpg';
import cj15 from '../assets/cj15.jpg';
import cj16 from '../assets/cj16.jpg';
import cj21 from '../assets/cj21.jpg';
import cj22 from '../assets/cj22.jpg';
import cj23 from '../assets/cj23.jpg';
import cj24 from '../assets/cj24.jpg';
import cj25 from '../assets/cj25.jpg';
import cj26 from '../assets/cj26.jpg';
import ertong1 from '../assets/ertong1.jpg';
import ertong2 from '../assets/ertong2.jpg';
import ertong3 from '../assets/ertong3.jpg';
import ertong4 from '../assets/ertong4.jpg';
import ertong5 from '../assets/ertong5.jpg';
import miaoshu from '../assets/miaoshu.png';
import xiaoguobg from '../assets/xiaoguobg.png';
import emote from '../assets/p810525.png'
import emote1 from '../assets/p810527.png'
import emote2 from '../assets/p810528.png'
import emote3 from '../assets/p810526.png'
import emote4 from '../assets/p810522.png'
import emote5 from '../assets/p810524.png'
import lchangjing1 from '../assets/changjing/pengpai.jpg';
import lchangjing2 from '../assets/changjing/jiepai.jpg';
import lchangjing3 from '../assets/changjing/haitan.jpg';
import lchangjing4 from '../assets/changjing/baisepengpai.jpg';
import lchangjing5 from '../assets/changjing/heisepengpai.jpg';
import lchangjing6 from '../assets/changjing/huisepengpai.jpg';
import lchangjing7 from '../assets/changjing/oushi.jpg';
import lchangjing8 from '../assets/changjing/rishi.jpg';
import lchangjing9 from '../assets/changjing/zhongshi.jpg';
import lchangjing10 from '../assets/changjing/senlin.jpg';
import lchangjing11 from '../assets/changjing/gaoshan.jpg';
import lchangjing12 from '../assets/changjing/yinghua.jpg';
import lchangjing13 from '../assets/changjing/gongyuan.jpg';
import lchangjing14 from '../assets/changjing/xiuchang.jpg';
import lchangjing15 from '../assets/changjing/nongchang.jpg';
import lchangjing16 from '../assets/changjing/caoping.jpg';
import lchangjing17 from '../assets/changjing/juhui.jpg';
import lchangjing18 from '../assets/changjing/yongchi.jpg';
import lchangjing19 from '../assets/changjing/jianshenfang.jpg';
import lchangjing20 from '../assets/changjing/tushuguan.jpg';
import lchangjing21 from '../assets/changjing/taikong.jpg';
import lchangjing22 from '../assets/changjing/matou.jpg';
import lchangjing23 from '../assets/changjing/jujia.jpg';
import lchangjing24 from '../assets/changjing/jiaotang.jpg';
import lchangjing25 from '../assets/changjing/youleyuan.jpg';
import lchangjing26 from '../assets/changjing/duocai.jpg';
import lchangjing27 from '../assets/changjing/xuejing.jpg';
import lchangjing28 from '../assets/changjing/ditie.jpg';
import lchangjing29 from '../assets/changjing/cheku.jpg';
import lchangjing30 from '../assets/changjing/louti.jpg';
import lchangjing31 from '../assets/changjing/tuya.jpg';
import lchangjing32 from '../assets/changjing/jietou.jpg';
import lchangjing33 from '../assets/changjing/zhantai.jpg';
import lchangjing34 from '../assets/changjing/oushiwoshi.jpg';
import lchangjing35 from '../assets/changjing/inswoshi.jpg';

export default {
  name: 'HorizontalContainers',
  props: ['menuIndex'],
  components: {
    PricingModal,
    ImageMore,

  },
  data() {
    return {
      mymianliao: '',
      mysex: '',
      mydanpin: '',
      mycolor: '',
      base64Image: '',
      tvalue: '0',
      mianliaolist: [
        "皮革",
        "全毛",
        "皮毛一体",
        "貂皮",
        "颗粒绒",
        "麂皮绒",
        "毛绒",
        "皮草",
        "牛仔面料",
        "蕾丝",
        "毛呢面料",
        "灯芯绒",
        "棉麻面料",
        "丝绸面料",
        "针织面料",
        "防护功能",
        "正装面料",
        "蕾丝面料",
        "棉纺面料",
        "麻纺面料",
        "雪纺",
        "时装化纤面料",
        "运动化纤面料",
        "混纺面料",
        "色织条格面料",
        "粗花呢面料",
        "针织毛呢面料",
        "精纺面料",
        "丝绒面料",
        "印花面料",
        "提花面料",
        "立体装饰面料",
        "特殊面料",
        "羊绒/羊毛",
        "马海毛/貂绒"
      ],
      sexlist: ["女装", "男装", "童装"],
      kuanxinglist: [
        "上衣",
        "T恤",
        "卫衣",
        "衬衫",
        "套衫",
        "开衫",
        "小开衫",
        "polo 衫",
        "背心",
        "打底衫",
        "吊带",
        "抹胸",
        "棉羽绒",
        "棉羽绒夹克",
        "中长棉羽绒",
        "羽绒马甲",
        "轻薄羽绒",
        "羽绒裤",
        "滑雪服",
        "外套",
        "大衣",
        "夹克",
        "皮夹克",
        "西装",
        "风衣",
        "皮风衣",
        "防晒服",
        "派克服",
        "冲锋衣",
        "马甲",
        "斗篷/披肩",
        "裙",
        "连衣裙",
        "半身裙",
        "背带裙",
        "裤",
        "中长裤",
        "短裤",
        "卫裤",
        "连体裤",
        "打底裤",
        "背带裤",
        "瑜伽裤",
        "皮裤",
        "套装",
        "西装套装",
        "运动套装",
        "卫衣套装",
        "时尚套装",
        "亲子装",
        "学院风套装",
        "家居装",
        "家居服",
        "睡衣套装",
        "睡裙",
        "睡袍",
        "睡裤",
        "睡衣吊带",
        "睡衣",
        "孕妇装",
        "内衣",
        "文胸",
        "内裤",
        "文胸套装",
        "保暖系列",
        "塑身衣",
        "婚纱",
        "A 字型婚纱",
        "鱼尾婚纱",
        "直身婚纱",
        "礼服",
        "小礼服",
        "晚礼服",
        "旗袍",
        "秀禾服",
        "泳装",
        "连体泳衣",
        "分体泳衣",
        "沙滩裙",
        "比基尼",
        "沙滩裤",
        "泳裤",
        "冲浪泳衣",
        "婴儿服",
        "包屁衣",
        "爬爬服",
        "服饰品",
        "帽子",
        "渔夫帽",
        "棒球帽",
        "针织帽",
        "时尚帽",
        "遮阳帽",
        "围巾",
        "丝巾",
        "针织围巾",
        "手套",
        "皮手套",
        "针织手套",
        "时尚手套",
        "袜子",
        "短袜",
        "筒袜",
        "丝袜",
        "连裤袜",
        "婴童用品",
        "领带",
        "领结",
        "腰带",
        "婴童用品",
        "礼盒",
        "口水兜/罩衣",
        "宝宝袜",
        "婴儿帽",
        "睡袋/抱被",
        "宠物用品",
        "宠物服饰",
        "宠物周边"
      ],
      colorlist: [
        "浅粉色",
        "粉红色",
        "玫红色",
        "红色",
        "暗红色",
        "米色",
        "驼色",
        "黄色",
        "金色",
        "栗色",
        "咖啡色",
        "橄榄绿",
        "绿色",
        "墨绿色",
        "浅蓝色",
        "蓝色",
        "深蓝色",
        "浅紫色",
        "紫色",
        "深紫色",
        "白色",
        "浅灰色",
        "中灰色",
        "深灰色",
        "黑色"
      ],
      panzoomInstance: null,
      scalePercent: 100,
      isLoadingXiaoguo: false,
      loadingText: '',
      imgPreviewVisible: false,
      //监测人台图
      prompts: '',
      hairstyles: [
        "A stunning young lady with flowing brown hair and a radiant smile.",
        "Long, straight hair with a slight wave, parted in the center, cascading smoothly down past the shoulders.",
        "Long, wavy hair with gentle waves, parted slightly off-center, adding a casual yet elegant touch.",
        "Medium-length, layered hair with a side part, adding volume and texture for a modern and stylish appearance.",
        "Medium-length, straight hair with a slight inward curve at the ends, parted in the middle for a sleek and polished look.",
        "Long, wavy hair with defined curls, parted in the middle to enhance volume and bounce.",
        "Medium-length, straight hair with bangs, slightly layered, with bangs cut straight across the forehead.",
        "Medium-length, wavy hair with a tousled look, parted in the center for a relaxed and natural style.",
        "Short, bob haircut with straight hair, parted to the side, with ends slightly curled inward for a chic and sophisticated look.",
        `Male with side parting and the front hair styled upwards.`,
        `Male side-parted short quiff.`,
        `Male fashionable side-parted hair.`,
        `The man has a neatly styled modern haircut, with side parting and the top slicked back smoothly.`,
        `The man in the image sports a neatly styled, modern haircut with a side part and smooth, well-groomed hair on top.`,
        `Male with an elegant side-parted style and slicked-back look, neatly combed to one side.`
      ],
      base_image_url: '',
      mask_image_url: '',

      changjinglist: [
        'Studio Shoot', 'Street Shoot', 'Beach', 'White Studio Shoot', 'Black Studio Shoot',
        'Gray Studio Shoot', 'European Street Shoot', 'Japanese Street Shoot', 'Chinese Street Shoot',
        'Forest', 'Mountain', 'Cherry Blossoms', 'Park', 'Fashion Show', 'Farm', 'Lawn', 'Party',
        'Swimming Pool', 'Gym', 'Library', 'Space', 'Dock', 'Home', 'Church', 'Amusement Park',
        'Colorful', 'Snowy Scene', 'Subway', 'Garage', 'Stairs', 'Graffiti', 'Street', 'Platform',
        'European Bedroom', 'Instagram Bedroom'
      ],

      biaoqinglist: ['Shy', 'Playful', 'Laughing', 'Bashful', 'Angry', 'Sad', 'Serious', 'smile'],
      defaulttx: ['girl1', 'girl2', 'girl3', 'boy1', 'boy2', 'boy3'],
      koutulist: [],
      activeindex: 1,
      jiafaMemory: null,
      shangpinMemory: null,
      zhenrenMemory: null,
      rentaiMemory: null,
      miaoshuUrl: xiaoguobg,
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
      fileList4: [],
      uploadFile4: '',
      uploadUrl4: '',
      fileList3: [],
      uploadFile3: '',
      uploadUrl3: '',
      fileList2: [],
      uploadFile2: '',
      uploadUrl2: '',
      fileList1: [],
      uploadFile1: '', // 存储url，切换菜单时回显当前选中图片的边框色
      uploadUrl1: '', // 通过URL.createObjectURL创建的链接
      imgMoreVisible7: false,
      imgMoreVisible6: false,
      cjImgList2: [
        [
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj21.jpg',
            name: '鲜花'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj22.jpg',
            name: '春节'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj23.jpg',
            name: '梅花'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj24.jpg',
            name: '丝绸'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj25.jpg',
            name: '落日'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj26.jpg',
            name: '山林'
          }
        ],
      ],
      cjImgList1: [
        [
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj11.jpg',
            name: '雪地'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj12.jpg',
            name: '咖啡厅'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj13.jpg',
            name: '冰川'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj14.jpg',
            name: '蓝天白云'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj15.jpg',
            name: '海水'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cj16.jpg',
            name: '海滩'
          }
        ],
      ],
      radio: '比例不变',
      num: 1,
      imgMoreVisible5: false,
      saveChangjing: '',
      imgList5: [
        [
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/pengpai.jpg',
            name: '棚拍'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/jiepai.jpg',
            name: '街拍'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/haitan.jpg',
            name: '海滩'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/baisepengpai.jpg',
            name: '白色棚拍图'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/heisepengpai.jpg',
            name: '黑色棚拍图'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/huisepengpai.jpg',
            name: '灰色棚拍图'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/oushi.jpg',
            name: '欧式街拍'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/rishi.jpg',
            name: '日式街拍'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/zhongshi.jpg',
            name: '中式街拍'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/senlin.jpg',
            name: '森林'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/gaoshan.jpg',
            name: '高山'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/yinghua.jpg',
            name: '樱花'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/gongyuan.jpg',
            name: '公园'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/xiuchang.jpg',
            name: '秀场'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/nongchang.jpg',
            name: '农场'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/caoping.jpg',
            name: '草坪'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/juhui.jpg',
            name: '聚会'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/yongchi.jpg',
            name: '泳池'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/jianshenfang.jpg',
            name: '健身房'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/tushuguan.jpg',
            name: '图书馆'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/taikong.jpg',
            name: '太空'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/matou.jpg',
            name: '码头'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/jujia.jpg',
            name: '居家'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/jiaotang.jpg',
            name: '教堂'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/youleyuan.jpg',
            name: '游乐园'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/duocai.jpg',
            name: '多彩'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/xuejing.jpg',
            name: '雪景'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/ditie.jpg',
            name: '地铁'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/cheku.jpg',
            name: '车库'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/louti.jpg',
            name: '楼梯'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/tuya.jpg',
            name: '涂鸦'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/jietou.jpg',
            name: '街头'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/zhantai.jpg',
            name: '站台'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/oushiwoshi.jpg',
            name: '欧风卧室'
          },
          {
            url: 'http://www.chenfuzhizao.com.cn/changjing/inswoshi.jpg',
            name: 'ins风卧室'
          }
        ]
      ]
      ,
      imgMoreVisible4: false,
      saveBiaoqing: '',
      imgList4: [
        [
          {
            url: biaoqing1,
            name: '害羞'
          },
          {
            url: biaoqing2,
            name: '调皮'
          },
          {
            url: biaoqing3,
            name: '大笑'
          },
          {
            url: biaoqing4,
            name: '腼腆'
          },
          {
            url: biaoqing5,
            name: '发怒'
          },
          {
            url: biaoqing6,
            name: '伤心'
          },
          {
            url: biaoqing7,
            name: '认真'
          },
          {
            url: biaoqing8,
            name: '微笑'
          },

        ],
      ],
      saveFaxing: '', // 保存发型
      imgMoreVisible3: false,
      imgList3: [
        [
          faxing1,
          faxing2,
          faxing3,
          faxing4,
          faxing5,
          faxing6,
          faxing7,
          faxing8,
          faxing9,
          faxing10,
          faxing11,
          faxing12,
          faxing13,
          faxing14,
          faxing15,

        ],
      ],
      imgMoreVisible2: false,
      currentGroup: '', // 固定模特为1, 随机模特为2 ...
      currentSelected: '', // 给子组件传值目前选择的图片
      imgMoreVisible: false,
      saveMote: '', // 保存模特图片
      imgList1: [
        [
          mote1,
          mote2,
          mote3,
          mote4,
          mote5,
          mote6,
          mote7,
        ],

      ],
      imgList2: [
        [
          emote,
          emote1,
          emote2,
          emote3,
          emote4,
          emote5,

        ],
      ],
      currentMenu: '1',
      allResourcesLoaded: false,
      showModal: false,
      dialogVisible4: false,
      evisible: false,
      editurl: '',
      value: '',
      value1: '',
      value2: '',
      value3: '',
      models: [],
      options: [
        { value: 'Africa', label: '非洲' },
        { value: 'Asia', label: '亚洲' },
        { value: 'America', label: '美洲' },
        { value: 'Europe', label: '欧洲' },
        { value: 'Oceania', label: '大洋洲' }
      ],
      options1: [
        { value: 'female', label: '女' },
        { value: 'male', label: '男' }

      ],
      options2: [
        { value: '0-2', label: '0-2' },
        { value: '3-5', label: '3-5' },
        { value: '6-10', label: '6-10' },
        { value: '11-15', label: '11-15' },
        { value: '16-18', label: '16-18' },
        { value: '19-25', label: '19-25' },
        { value: '26-35', label: '26-35' },
        { value: '36-45', label: '36-45' },
        { value: '46-55', label: '46-55' },
        { value: '56-65', label: '56-65' },
        { value: '66+', label: '66+' }
      ],
      options3: [
        { value: '大笑', label: '大笑' },
        { value: '微笑', label: '微笑' },
        { value: '害羞', label: '害羞' },
        { value: '发怒', label: '发怒' },
        { value: '伤心', label: '伤心' },

        { value: '调皮', label: '调皮' },
        { value: '眨眼', label: '眨眼' },
        { value: '无表情', label: '无表情' },
        { value: '认真', label: '认真' },
        { value: '腼腆', label: '腼腆' },
        { value: '闭眼', label: '闭眼' },
        { value: '哭', label: '哭' },
        { value: '尴尬', label: '尴尬' },
        { value: '生气', label: '生气' },
        { value: '恐惧', label: '恐惧' },

        { value: '厌恶', label: '厌恶' },
        { value: '悠闲', label: '悠闲' },
        { value: '难过', label: '难过' },
        { value: '惊讶', label: '惊讶' },
      ],
      loading: false,
      myloading: '',
      vpath1: '',
      vpath: '',
      page: 1,
      limit: 10,
      observer: null,
      dialogVisible2: false,
      categoryMap: {
        first0: { continent: '', gender: '' },
        first1: { continent: 'Asia', gender: 'male' },
        first2: { continent: 'Asia', gender: 'female' },
        first3: { continent: 'America', gender: 'male' },
        first4: { continent: 'America', gender: 'female' },
        first5: { continent: 'Europe', gender: 'male' },
        first6: { continent: 'Europe', gender: 'female' },
        first7: { continent: 'Africa', gender: 'male' },
        first8: { continent: 'Africa', gender: 'female' },
        first9: { continent: 'Singapore', gender: 'male' },
        first10: { continent: 'Singapore', gender: 'female' }
      },
      activeName3: 'first0',
      uploads: [],
      dialogVisible1: false,
      username: localStorage.username,
      dialogVisible: false,
      activeName: 'first',
      activeName1: 'first',
      mytext: '上传上身正面图',
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      url1: 'http://www.chenfuzhizao.com.cn/gd/00055_00.jpg',
      furl1: 'http://www.chenfuzhizao.com.cn/gd/00055_00.jpg',
      url2: 'http://www.chenfuzhizao.com.cn/gd/00126_00.jpg',
      url3: 'http://www.chenfuzhizao.com.cn/gd/00151_00.jpg',
      url4: 'http://www.chenfuzhizao.com.cn/gd/00470_00.jpg',
      lurl1: 'http://www.chenfuzhizao.com.cn/wj/051515_1.jpg',
      lurl2: 'http://www.chenfuzhizao.com.cn/wj/051517_1.jpg',
      lurl3: 'http://www.chenfuzhizao.com.cn/wj/051827_1.jpg',
      lurl4: 'http://www.chenfuzhizao.com.cn/wj/051946_1.jpg',
      surl1: 'http://www.chenfuzhizao.com.cn/1719969857625.png',
      surl2: 'http://www.chenfuzhizao.com.cn/1719812281699.png',
      surl3: 'http://www.chenfuzhizao.com.cn/1719812878787.png',
      dialogtitle1: '海量AI模特库',
      shangmiantu: '',
      outputPath: '',
      loutputPath: '',
      lshangmiantu: '',

      selectstyle: '0',
      dialogtitle: '上传上身正面图',
      moteurl: 'http://www.chenfuzhizao.com.cn/moren/weishangchuan.png',
      yurl1: 'http://www.chenfuzhizao.com.cn/1719812281699.png',
      dialogVisible3: false,
      dpath: '',
      lturl1: 'http://www.chenfuzhizao.com.cn/lianti/053742_1.jpg',
      lturl2: 'http://www.chenfuzhizao.com.cn/lianti/053744_1.jpg',
      lturl3: 'http://www.chenfuzhizao.com.cn/lianti/053786_1.jpg',
      lturl4: 'http://www.chenfuzhizao.com.cn/lianti/053790_1.jpg',


    };
  },
  computed: {
    uploadUrl() {
      switch (this.currentMenu) {
        case '1':
          return '';
        case '2':
          return this.uploadUrl1;
        case '3':
          return this.uploadUrl2;
        case '4':
          return this.uploadUrl3;
        case '5':
          return this.uploadUrl4;
        case '6':
          return '';
        case '7':
          return '';
      }
    },
    uploadFile() {
      switch (this.currentMenu) {
        case '2':
          return this.uploadFile1;
        case '3':
          return this.uploadFile2;
        case '4':
          return this.uploadFile3;
        case '5':
          return this.uploadFile4;
      }
    },
    description() {
      switch (this.currentMenu) {
        case '2':
          return '只需上传人台试装图（纯色背景更佳）';
        case '3':
          return '只需上传真人试装图（纯色背景更佳）';
        case '4':
          return '只需上传商品图，即可生成具备商业摄影效果的产品宣传图';
        case '5':
          return '只需上传人台或素人假发试戴图，即可获得多样背景的定制模特试发图';
      }
    }
  },
  watch: {
    currentMenu(newVal, oldVal) {
      // 切换菜单记忆功能
      if (oldVal === '2') {
        this.rentaiMemory = {
          mote: this.saveMote,
          faxing: this.saveFaxing,
          biaoqing: this.saveBiaoqing,
          changjing: this.saveChangjing,
          num: this.num
        };
      }
      if (oldVal === '3') {
        this.zhenrenMemory = {
          mote: this.saveMote,
          faxing: this.saveFaxing,
          biaoqing: this.saveBiaoqing,
          changjing: this.saveChangjing,
          num: this.num,
          bili: this.radio
        };
      }
      if (oldVal === '4') {
        this.shangpinMemory = {
          changjing: this.saveChangjing,
          bili: this.radio,
          num: this.num
        };
      }
      if (oldVal === '5') {
        this.jiafaMemory = {
          mote: this.saveMote,
          biaoqing: this.saveBiaoqing,
          changjing: this.saveChangjing,
          num: this.num
        };
      }
      if (newVal === '2') {
        this.saveMote = this.rentaiMemory ? this.rentaiMemory.mote : '';
        this.saveFaxing = this.rentaiMemory ? this.rentaiMemory.faxing : '';
        this.saveBiaoqing = this.rentaiMemory ? this.rentaiMemory.biaoqing : '';
        this.saveChangjing = this.rentaiMemory ? this.rentaiMemory.changjing : '';
        this.num = this.rentaiMemory ? this.rentaiMemory.num : 1;
      }
      if (newVal === '3') {
        this.saveMote = this.zhenrenMemory ? this.zhenrenMemory.mote : '';
        this.saveFaxing = this.zhenrenMemory ? this.zhenrenMemory.faxing : '';
        this.saveBiaoqing = this.zhenrenMemory ? this.zhenrenMemory.biaoqing : '';
        this.saveChangjing = this.zhenrenMemory ? this.zhenrenMemory.changjing : '';
        this.radio = this.zhenrenMemory ? this.zhenrenMemory.bili : '比例不变';
        this.num = this.zhenrenMemory ? this.zhenrenMemory.num : 1;
      }
      if (newVal === '4') {
        this.saveChangjing = this.shangpinMemory ? this.shangpinMemory.changjing : '';
        this.radio = this.shangpinMemory ? this.shangpinMemory.bili : '比例不变';
        this.num = this.shangpinMemory ? this.shangpinMemory.num : 1;
      }
      if (newVal === '5') {
        this.saveMote = this.jiafaMemory ? this.jiafaMemory.mote : '';
        this.saveBiaoqing = this.jiafaMemory ? this.jiafaMemory.biaoqing : '';
        this.saveChangjing = this.jiafaMemory ? this.jiafaMemory.changjing : '';
        this.num = this.jiafaMemory ? this.jiafaMemory.num : 1;
      }
    }
  },
  mounted() {
    if (this.menuIndex) {
      this.selectMenu(this.menuIndex)
    }
    this.myloading = this.$loading({
      lock: true,
      text: "正在加载中请稍等~",
      // spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.2)"
    });

    this.$nextTick(() => {
      this.checkAllResourcesLoaded();
    });
    this.loadMoreModels();
  },
  beforeDestroy() {


  },
  methods: {
    mianliao(index) {
      this.mymianliao = this.mianliaolist[index]
    },
    sex(index) {
      this.mysex = this.sexlist[index]
    },
    danpin(index) {
      // alert(index)
      this.mydanpin = this.kuanxinglist[index]
    },
    yanse(index) { this.mycolor = this.colorlist[index] }

    ,
    handleFileChange(file1) {
      console.log(file1)
      const file = file1;
      this.convertToBase64(file.raw).then(base64 => {
        this.base64Image = base64;
        // alert(this.base64Image)
      });

    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    gotohuanzhuang() {
      this.$message.warning("选择该训练结果，用作换装功能～")
      this.currentMenu = '1'
      this.moteurl = this.vpath1
    },
    gotozhenrentu() {
      this.$message.warning("选择该训练结果，用作真人图的功能～")
      this.currentMenu = '3'
      this.saveMote = this.vpath1
      this.loadingdata()
    },
    gotorentai() {
      this.$message.warning("选择该训练结果，用作人台图的功能～")
      this.currentMenu = '2'
      this.saveMote = this.vpath1
      this.loadingdata()
    },
    gotozhenren() {
      this.currentMenu = '3'
    },
    gotoshangpin() {
      this.currentMenu = '4'
    },
    gotojiafa() {
    },
    zoomIn() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomIn();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    zoomOut() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomOut();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    resetZoom() {
      if (this.panzoomInstance) {
        this.panzoomInstance.reset();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    updateScalePercent() {
      // 获取当前的缩放比例
      const currentScale = this.panzoomInstance.getScale();
      // 计算百分比，并四舍五入保留两位小数
      this.scalePercent = Math.round(currentScale * 100);
    },
    async kuotu() {
      var that = this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 1) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在扩图中～",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const response3 = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.vpath1,
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var imagurl1 = response3.data.url
      const params = {
        image_url: imagurl1,

      };

      const res = await apiClient.get(`/image-out-painting`, {
        params: params,
        headers: {
          'x-access-token': localStorage.token
        },
      });
      var t1 = setInterval(async function () {

        var taskid = res.data.output.task_id
        var tresponse1 = await apiClient.get('/gettask1', {
          params: {
            taskId: taskid,

          }
        });
        console.log(JSON.stringify(tresponse1.data))
        var status12 = tresponse1.data.status12.task_status
        console.log(status12)
        if (status12 == 'FAILED') {
          clearInterval(t1)

          // loading.close();
          that.isLoadingXiaoguo = false;
          that.$message.error("扩图失败~")
        }
        if (status12 == 'SUCCEEDED') {
          clearInterval(t1)
          var imgurl2 = tresponse1.data.status12.output_image_url
          var response2 = await apiClient.get('/tdownloadmyurl', {
            params: {
              url2: imgurl2,

            }
          })
          var path2 = response2.data.outputPath
          var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
          //  alert(vpath)
          that.vpath1 = vpath
          var tresponse1 = await apiClient.get('/inserrlist', {
            params: {
              resulturl: that.vpath1,
              username: localStorage.username,

            }
          });
          clearInterval(t1)
          that.miaoshuUrl = ''
          that.koutulist = []
          // that.koujian()


          // loading.close();
          that.isLoadingXiaoguo = false;
        }
      }, 8000)


    },
    async lianbuxiufu() {
      var that = this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 1) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在修复模特面部～",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.vpath1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      // alert(myurl1)
      //开始抠
      const response1 = await apiClient.get('/lianbuxiufu', {

        params: {
          imageURL: myurl1,

        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.vpath1 = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      var tresponse1 = await apiClient.get('/inserrlist', {
        params: {
          resulturl: that.vpath1,
          username: localStorage.username,

        }
      });
      that.koujian('7')
      // loading.close();
      that.isLoadingXiaoguo = false;


    },
    async AIGenerate2() {
      var that = this


      if (this.value.length == 0) {
        this.$message.warning('请选择地区');
        return false
      }
      if (this.value1.length == 0) {
        this.$message.warning('请选择性别');
        return false
      }
      if (this.value2.length == 0) {
        this.$message.warning('请选择年龄');
        return false
      }
      if (this.value3.length == 0) {
        this.$message.warning('请选择笑容');
        return false
      }
      if (this.prompts.length == 0) {
        this.$message.warning('请输入提示词');
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在生成您的模特",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~'
      try {
        const response = await apiClient.get('/generateModel4', {
          params: {
            continent: this.value,
            gender: this.value1,
            ageRange: this.value2,
            expression: this.value3,
            prompts: this.prompts,


          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        // Handle the response as needed, for example, display the image
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {


          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("模特图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl1', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,

              }
            });

            // var tresponse2 = await apiClient.get('/savemote', {
            //   params: {
            //     filePath: path2,
            //     username: localStorage.username,

            //   }
            // });
            //  var tresponse3 = await apiClient.get('/insertmodels12', {
            //    params: {
            //      continent: that.value, gender: that.value1, age: that.value2, imagePath: path2,xianshi:'3'
            //     }
            //   });
            clearInterval(t1)
            that.vpath1 = that.dpath
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('4')
            // loading.close()
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async AIGenerate4() {
      var that=this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
    
      if (this.prompts == '') {
        this.$message.error("请输入提示词~")
        return false
      }
     
      if (this.mycolor == '') {
        this.$message.error("请选择颜色~")
        return false
      }
      if (this.mydanpin == '') {
        this.$message.error("请选择衣服品类~")
        return false
      }
      if (this.mysex == '') {
        this.$message.error("请选择性别~")
        return false
      }
      if (this.mymianliao == '') {
        this.$message.error("请选择面料~")
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const requestData = {
        promptText: this.prompts,
        color: this.mycolor,
        categoryAndName: this.mydanpin,
        gender: this.mysex,
        fabric: this.mymianliao,
      
      };
      var headers= {
          'x-access-token': localStorage.token
        }
     
      const response = await apiClient.post('/imageToImage1', requestData,{headers});
        console.log(response);
        if (response.data.message === 'image successfully') {
          this.$message.success('改款成功！');
          this.isLoadingXiaoguo = false
            var path2 = response.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            that.vpath1=vpath
            that.koujian('2')
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
          // 可以在这里处理返回的 outputPath，比如显示图片等操作
        } else {
          this.$message.error('改款失败，请稍后重试。');
          this.isLoadingXiaoguo = false
        }
    },
    async AIGenerate3() {
      var that=this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      if (this.base64Image == '') {
        this.$message.error("请先上传改款的衣服图片~")
        return false
      }
      if (this.prompts == '') {
        this.$message.error("请输入提示词~")
        return false
      }
      if (this.tvalue == '0') {
        this.$message.error("请调整衣服改款相似度~")
        return false
      }
      if (this.mycolor == '') {
        this.$message.error("请选择颜色~")
        return false
      }
      if (this.mydanpin == '') {
        this.$message.error("请选择衣服品类~")
        return false
      }
      if (this.mysex == '') {
        this.$message.error("请选择性别~")
        return false
      }
      if (this.mymianliao == '') {
        this.$message.error("请选择面料~")
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const requestData = {
        promptText: this.prompts,
        color: this.mycolor,
        categoryAndName: this.mydanpin,
        gender: this.mysex,
        fabric: this.mymianliao,
        Strength: this.tvalue,
        InputImage: this.base64Image,
      };

      // 调用改款 API
      var headers= {
          'x-access-token': localStorage.token
        }
     
      
        const response = await apiClient.post('/imageToImage', requestData,{headers});
        console.log(response);
        if (response.data.message === 'image successfully') {
          this.$message.success('改款成功！');
          this.isLoadingXiaoguo = false
            var path2 = response.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            that.vpath1=vpath
            that.koujian('2')
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
          // 可以在这里处理返回的 outputPath，比如显示图片等操作
        } else {
          this.$message.error('改款失败，请稍后重试。');
          this.isLoadingXiaoguo = false
        }
      
    },
    async AIGenerate6() {
      var that=this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      if (this.base64Image == '') {
        this.$message.error("请先上传改款的衣服图片~")
        return false
      }
      if (this.prompts == '') {
        this.$message.error("请输入提示词~")
        return false
      }
      if (this.tvalue == '0') {
        this.$message.error("请调整衣服改款相似度~")
        return false
      }
      if (this.mycolor == '') {
        this.$message.error("请选择颜色~")
        return false
      }
      if (this.mydanpin == '') {
        this.$message.error("请选择衣服品类~")
        return false
      }
      if (this.mysex == '') {
        this.$message.error("请选择性别~")
        return false
      }
      if (this.mymianliao == '') {
        this.$message.error("请选择面料~")
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const requestData = {
        promptText: this.prompts,
        color: this.mycolor,
        categoryAndName: this.mydanpin,
        gender: this.mysex,
        fabric: this.mymianliao,
        Strength: this.tvalue,
        InputImage: this.base64Image,
      };

      // 调用改款 API
      var headers= {
          'x-access-token': localStorage.token
        }
     
        const response = await apiClient.post('/imageToImage3', requestData,{headers});
        console.log(response);
        if (response.data.message === 'image successfully') {
          this.$message.success('改款成功！');
          this.isLoadingXiaoguo = false
            var path2 = response.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            that.vpath1=vpath
            that.koujian('2')
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
          // 可以在这里处理返回的 outputPath，比如显示图片等操作
        } else {
          this.$message.error('改款失败，请稍后重试。');
          this.isLoadingXiaoguo = false
        }
      
    },
    async AIGenerate5() {
      var that=this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      if (this.base64Image == '') {
        this.$message.error("请先上传改款的衣服图片~")
        return false
      }
      if (this.prompts == '') {
        this.$message.error("请输入提示词~")
        return false
      }
      if (this.tvalue == '0') {
        this.$message.error("请调整衣服改款相似度~")
        return false
      }
     
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const requestData = {
        promptText: this.prompts,
       
        Strength: this.tvalue,
        InputImage: this.base64Image,
      };

      // 调用改款 API
      var headers= {
          'x-access-token': localStorage.token
        }
     
        const response = await apiClient.post('/imageToImage2', requestData,{headers});
        console.log(response);
        if (response.data.message === 'image successfully') {
          this.$message.success('改款成功！');
          this.isLoadingXiaoguo = false
            var path2 = response.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            that.vpath1=vpath
            that.koujian('2')
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
          // 可以在这里处理返回的 outputPath，比如显示图片等操作
        } else {
          this.$message.error('改款失败，请稍后重试。');
          this.isLoadingXiaoguo = false
        }
      
    },
    async AIGenerate5() {
      var that=this
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      if (this.base64Image == '') {
        this.$message.error("请先上传改款的衣服图片~")
        return false
      }
      if (this.prompts == '') {
        this.$message.error("请输入提示词~")
        return false
      }
      if (this.tvalue == '0') {
        this.$message.error("请调整衣服改款相似度~")
        return false
      }
     
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const requestData = {
        promptText: this.prompts,
       
        Strength: this.tvalue,
        InputImage: this.base64Image,
      };

      // 调用改款 API
      var headers= {
          'x-access-token': localStorage.token
        }
     
        const response = await apiClient.post('/imageToImage2', requestData,{headers});
        console.log(response);
        if (response.data.message === 'image successfully') {
          this.$message.success('改款成功！');
          this.isLoadingXiaoguo = false
            var path2 = response.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            that.vpath1=vpath
            that.koujian('2')
          // 可以在这里处理返回的 outputPath，比如显示图片等操作
        } else {
          this.$message.error('改款失败，请稍后重试。');
          this.isLoadingXiaoguo = false
        }
      
    },
    async AIGenerate1() {
      var that = this


      if (this.value.length == 0) {
        this.$message.warning('请选择地区');
        return false
      }
      if (this.value1.length == 0) {
        this.$message.warning('请选择性别');
        return false
      }
      if (this.value2.length == 0) {
        this.$message.warning('请选择年龄');
        return false
      }
      if (this.value3.length == 0) {
        this.$message.warning('请选择笑容');
        return false
      }
      if (this.prompts.length == 0) {
        this.$message.warning('请输入提示词');
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在生成您的模特",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      try {
        const response = await apiClient.get('/generateModel3', {
          params: {
            continent: this.value,
            gender: this.value1,
            ageRange: this.value2,
            expression: this.value3,
            prompts: this.prompts,


          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        // Handle the response as needed, for example, display the image
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {


          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("模特图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,

              }
            });

            // var tresponse2 = await apiClient.get('/savemote', {
            //   params: {
            //     filePath: path2,
            //     username: localStorage.username,

            //   }
            // });
            // var tresponse3 = await apiClient.get('/insertmodels12', {
            //  params: {
            //    continent: that.value, gender: that.value1, age: that.value2, imagePath: path2,xianshi:'2'
            //   }
            // });
            clearInterval(t1)
            that.vpath1 = that.dpath
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('3')
            // loading.close()
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async AIGenerate() {
      var that = this
     
      console.log('模特', this.saveMote)
      console.log('表情', this.saveBiaoqing)
      console.log('发型', this.saveFaxing)
      console.log('场景', this.saveChangjing)
      console.log('数量', this.num)
      console.log('比例', this.radio)
      var predefined_face_id = ''
      var face_image_url = ''
      var model_description = ''
      var background_description = ''
      this.uploadUrl = ''
      this.vpath = ''
      this.vpath1 = ''


      if (this.currentMenu === '2') {
        var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
        // 人台图
        if (this.base_image_url == '') {
          this.$message.error('请上传人台图～');
          return false
        }
        if (this.mask_image_url == '') {
          this.$message.error('服饰区域图片不能为空～');
          return false
        }
        if (this.saveMote === '') {
          this.$message.error('模特图片不能为空～');
          return false
        }
        if (this.saveBiaoqing == '') {
          this.$message.error('表情图片不能为空～');
          return false
        }
        if (this.saveChangjing == '') {
          this.$message.error('场景图片不能为空～');
          return false
        }
        if (this.saveFaxing == '') {
          this.$message.error('发型不能为空～');
          return false
        }
        if (this.saveMote.indexOf("http") > -1) {
          //随机模特
          // var loading = this.$loading({
          //   lock: true,
          //   text: "正在处理随机肖像稍等~",
          //   // spinner: "el-icon-loading",
          //   background: "rgba(0, 0, 0, 0.2)"
          // });
          this.isLoadingXiaoguo = true;
          this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
          const response = await apiClient.get('/upload-image', {

            params: {
              imageUrl: this.saveMote
            },
            headers: {
              'x-access-token': localStorage.token
            }
          });
          face_image_url = response.data.url
          // loading.close()
          this.isLoadingXiaoguo = false;
        }
        else {
          var eindex = 0
          // alert(this.saveMote)
          if (this.saveMote.indexOf('p810525') > -1) {
            eindex = 0
          }
          if (this.saveMote.indexOf('p810527') > -1) {
            eindex = 1
          }
          if (this.saveMote.indexOf('p810528') > -1) {
            eindex = 2
          }
          if (this.saveMote.indexOf('p810526') > -1) {
            eindex = 3
          }
          if (this.saveMote.indexOf('p810522') > -1) {
            eindex = 4
          }
          if (this.saveMote.indexOf('p810524') > -1) {
            eindex = 5

          }
          predefined_face_id = this.defaulttx[eindex]
        }
        var eindex = 0
        // alert(this.saveMote)
        if (this.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (this.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (this.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (this.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (this.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (this.saveMote.indexOf('p810524') > -1) {
          eindex = 5

        }
        model_description = this.defaulttx[eindex]
        var eindex = 0
        // alert(this.saveMote)
        if (this.saveChangjing.indexOf('pengpai') > -1) {
          eindex = 0
        }
        if (this.saveChangjing.indexOf('jiepai') > -1) {
          eindex = 1
        }
        if (this.saveChangjing.indexOf('hai') > -1) {
          eindex = 2
        }
        if (this.saveChangjing.indexOf('baise') > -1) {
          eindex = 3
        }
        if (this.saveChangjing.indexOf('heise') > -1) {
          eindex = 4
        }
        if (this.saveChangjing.indexOf('huise') > -1) {
          eindex = 5
        }
        if (this.saveChangjing.indexOf('oushi') > -1) {
          eindex = 6

        }
        if (this.saveChangjing.indexOf('rishi') > -1) {
          eindex = 7

        }
        if (this.saveChangjing.indexOf('zhongshi') > -1) {
          eindex = 8

        }
        if (this.saveChangjing.indexOf('senlin') > -1) {
          eindex = 9

        }
        if (this.saveChangjing.indexOf('gaoshan') > -1) {
          eindex = 10

        }
        if (this.saveChangjing.indexOf('yinghua') > -1) {
          eindex = 11

        }
        if (this.saveChangjing.indexOf('gongyuan') > -1) {
          eindex = 12

        }
        if (this.saveChangjing.indexOf('xiuchang') > -1) {
          eindex = 13

        }
        if (this.saveChangjing.indexOf('nongchang') > -1) {
          eindex = 14

        }
        if (this.saveChangjing.indexOf('cao') > -1) {
          eindex = 15

        }
        if (this.saveChangjing.indexOf('juhui') > -1) {
          eindex = 16

        }
        if (this.saveChangjing.indexOf('yongchi') > -1) {
          eindex = 17

        }
        if (this.saveChangjing.indexOf('jianshen') > -1) {
          eindex = 18

        }
        if (this.saveChangjing.indexOf('tushu') > -1) {
          eindex = 19

        }
        if (this.saveChangjing.indexOf('taikong') > -1) {
          eindex = 20

        }
        if (this.saveChangjing.indexOf('matou') > -1) {
          eindex = 21

        }
        if (this.saveChangjing.indexOf('jujia') > -1) {
          eindex = 22

        }
        if (this.saveChangjing.indexOf('jiao') > -1) {
          eindex = 23

        }
        if (this.saveChangjing.indexOf('youle') > -1) {
          eindex = 24

        }
        if (this.saveChangjing.indexOf('duocai') > -1) {
          eindex = 25

        }
        if (this.saveChangjing.indexOf('xuejing') > -1) {
          eindex = 26

        }
        if (this.saveChangjing.indexOf('ditie') > -1) {
          eindex = 27

        }
        if (this.saveChangjing.indexOf('cheku') > -1) {
          eindex = 28

        }
        if (this.saveChangjing.indexOf('louti') > -1) {
          eindex = 29

        }
        if (this.saveChangjing.indexOf('tuiya') > -1) {
          eindex = 30

        }
        if (this.saveChangjing.indexOf('jietou') > -1) {
          eindex = 31

        }
        if (this.saveChangjing.indexOf('zhantai') > -1) {
          eindex = 32

        }
        if (this.saveChangjing.indexOf('oushiwoshi') > -1) {
          eindex = 33

        }
        if (this.saveChangjing.indexOf('inswoshi') > -1) {
          eindex = 34

        }
        var myindex = 0
        if (this.saveFaxing.indexOf('faxing1') > -1) {
          myindex = 0
        }
        if (this.saveFaxing.indexOf('faxing2') > -1) {
          myindex = 1
        }
        if (this.saveFaxing.indexOf('faxing3') > -1) {
          myindex = 2
        }
        if (this.saveFaxing.indexOf('faxing4') > -1) {
          myindex = 3
        }
        if (this.saveFaxing.indexOf('faxing5') > -1) {
          myindex = 4
        }
        if (this.saveFaxing.indexOf('faxing6') > -1) {
          myindex = 5
        }
        if (this.saveFaxing.indexOf('faxing7') > -1) {
          myindex = 6
        }
        if (this.saveFaxing.indexOf('faxing8') > -1) {
          myindex = 7
        }
        if (this.saveFaxing.indexOf('faxing9') > -1) {
          myindex = 8
        }
        if (this.saveFaxing.indexOf('faxing10') > -1) {
          myindex = 9
        }
        if (this.saveFaxing.indexOf('faxing11') > -1) {
          myindex = 10
        }
        if (this.saveFaxing.indexOf('faxing12') > -1) {
          myindex = 11
        }
        if (this.saveFaxing.indexOf('faxing13') > -1) {
          myindex = 12
        }
        if (this.saveFaxing.indexOf('faxing14') > -1) {
          myindex = 13
        }
        if (this.saveFaxing.indexOf('faxing15') > -1) {
          myindex = 14
        }
        var hairstyles = this.hairstyles[myindex]
        var eindex = 0
        if (this.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (this.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (this.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (this.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (this.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (this.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        if (this.saveBiaoqing.indexOf('biaoqing7') > -1) {
          eindex = 6
        }
        if (this.saveBiaoqing.indexOf('biaoqing8') > -1) {
          eindex = 7
        }
        var biaoqing = this.biaoqinglist[eindex]
        // alert(biaoqing)
        // var loading = this.$loading({
        //   lock: true,
        //   text: "正在完成人台图，请稍等~",
        //   // spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.2)"
        // });
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        background_description = this.changjinglist[eindex]
        const response3 = await apiClient.get('/upload-image', {

          params: {
            imageUrl: this.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url


        // alert(background_description)
        const params = {
          base_image_url: this.base_image_url,
          mask_image_url: this.mask_image_url,
          predefined_face_id: predefined_face_id,
          background_image_url: background_image_url,
          face_image_url: face_image_url,
          model_description: model_description,
          background_description: background_description,
          aspect_ratio: this.radio,
          hairstyle_description: hairstyles,

          biaoqing: biaoqing
        };

        const res = await apiClient.get(`/getrentaitu`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        console.log(JSON.stringify(res.data))
        var t1 = setInterval(async function () {

          var taskid = res.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          console.log(status12)
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("人台图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.vpath1 = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
            clearInterval(t1)
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('2')


            // loading.close();
            that.isLoadingXiaoguo = false;
          }
        }, 8000)



      }
      if (this.currentMenu === '3') {
        // 真人图
        var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
        if (this.base_image_url == '') {
          this.$message.error('请上传人台图～');
          return false
        }
        if (this.mask_image_url == '') {
          this.$message.error('服饰区域图片不能为空～');
          return false
        }
        if (this.saveMote === '') {
          this.$message.error('模特图片不能为空～');
          return false
        }
        if (this.saveBiaoqing == '') {
          this.$message.error('表情图片不能为空～');
          return false
        }
        if (this.saveChangjing == '') {
          this.$message.error('场景图片不能为空～');
          return false
        }
        if (this.saveFaxing == '') {
          this.$message.error('发型不能为空～');
          return false
        }
        if (this.saveMote.indexOf("http") > -1) {
          // var loading = this.$loading({
          //   lock: true,
          //   text: "正在处理随机肖像稍等~",
          //   // spinner: "el-icon-loading",
          //   background: "rgba(0, 0, 0, 0.2)"
          // });
          this.isLoadingXiaoguo = true;
          this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
          //随机模特
          const response = await apiClient.get('/upload-image', {

            params: {
              imageUrl: this.saveMote
            },
            headers: {
              'x-access-token': localStorage.token
            }
          });
          face_image_url = response.data.url
          // loading.close()
          this.isLoadingXiaoguo = false;
        }
        else {
          var eindex = 0
          // alert(this.saveMote)
          if (this.saveMote.indexOf('p810525') > -1) {
            eindex = 0
          }
          if (this.saveMote.indexOf('p810527') > -1) {
            eindex = 1
          }
          if (this.saveMote.indexOf('p810528') > -1) {
            eindex = 2
          }
          if (this.saveMote.indexOf('p810526') > -1) {
            eindex = 3
          }
          if (this.saveMote.indexOf('p810522') > -1) {
            eindex = 4
          }
          if (this.saveMote.indexOf('p810524') > -1) {
            eindex = 5

          }
          predefined_face_id = this.defaulttx[eindex]
        }
        var eindex = 0
        // alert(this.saveMote)
        if (this.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (this.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (this.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (this.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (this.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (this.saveMote.indexOf('p810524') > -1) {
          eindex = 5

        }
        model_description = this.defaulttx[eindex]
        var eindex = 0
        // alert(this.saveMote)
        if (this.saveChangjing.indexOf('pengpai') > -1) {
          eindex = 0
        }
        if (this.saveChangjing.indexOf('jiepai') > -1) {
          eindex = 1
        }
        if (this.saveChangjing.indexOf('hai') > -1) {
          eindex = 2
        }
        if (this.saveChangjing.indexOf('baise') > -1) {
          eindex = 3
        }
        if (this.saveChangjing.indexOf('heise') > -1) {
          eindex = 4
        }
        if (this.saveChangjing.indexOf('huise') > -1) {
          eindex = 5
        }
        if (this.saveChangjing.indexOf('oushi') > -1) {
          eindex = 6

        }
        if (this.saveChangjing.indexOf('rishi') > -1) {
          eindex = 7

        }
        if (this.saveChangjing.indexOf('zhongshi') > -1) {
          eindex = 8

        }
        if (this.saveChangjing.indexOf('senlin') > -1) {
          eindex = 9

        }
        if (this.saveChangjing.indexOf('gaoshan') > -1) {
          eindex = 10

        }
        if (this.saveChangjing.indexOf('yinghua') > -1) {
          eindex = 11

        }
        if (this.saveChangjing.indexOf('gongyuan') > -1) {
          eindex = 12

        }
        if (this.saveChangjing.indexOf('xiuchang') > -1) {
          eindex = 13

        }
        if (this.saveChangjing.indexOf('nongchang') > -1) {
          eindex = 14

        }
        if (this.saveChangjing.indexOf('cao') > -1) {
          eindex = 15

        }
        if (this.saveChangjing.indexOf('juhui') > -1) {
          eindex = 16

        }
        if (this.saveChangjing.indexOf('yongchi') > -1) {
          eindex = 17

        }
        if (this.saveChangjing.indexOf('jianshen') > -1) {
          eindex = 18

        }
        if (this.saveChangjing.indexOf('tushu') > -1) {
          eindex = 19

        }
        if (this.saveChangjing.indexOf('taikong') > -1) {
          eindex = 20

        }
        if (this.saveChangjing.indexOf('matou') > -1) {
          eindex = 21

        }
        if (this.saveChangjing.indexOf('jujia') > -1) {
          eindex = 22

        }
        if (this.saveChangjing.indexOf('jiao') > -1) {
          eindex = 23

        }
        if (this.saveChangjing.indexOf('youle') > -1) {
          eindex = 24

        }
        if (this.saveChangjing.indexOf('duocai') > -1) {
          eindex = 25

        }
        if (this.saveChangjing.indexOf('xuejing') > -1) {
          eindex = 26

        }
        if (this.saveChangjing.indexOf('ditie') > -1) {
          eindex = 27

        }
        if (this.saveChangjing.indexOf('cheku') > -1) {
          eindex = 28

        }
        if (this.saveChangjing.indexOf('louti') > -1) {
          eindex = 29

        }
        if (this.saveChangjing.indexOf('tuiya') > -1) {
          eindex = 30

        }
        if (this.saveChangjing.indexOf('jietou') > -1) {
          eindex = 31

        }
        if (this.saveChangjing.indexOf('zhantai') > -1) {
          eindex = 32

        }
        if (this.saveChangjing.indexOf('oushiwoshi') > -1) {
          eindex = 33

        }
        if (this.saveChangjing.indexOf('inswoshi') > -1) {
          eindex = 34

        }
        // var loading = this.$loading({
        //   lock: true,
        //   text: "AI图形正在生成中，请耐心等待1-2分钟~",
        //   // spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.2)"
        // });
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        background_description = this.changjinglist[eindex]
        const response3 = await apiClient.get('/upload-image', {

          params: {
            imageUrl: this.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url
        var myindex = 0
        if (this.saveFaxing.indexOf('faxing1') > -1) {
          myindex = 0
        }
        if (this.saveFaxing.indexOf('faxing2') > -1) {
          myindex = 1
        }
        if (this.saveFaxing.indexOf('faxing3') > -1) {
          myindex = 2
        }
        if (this.saveFaxing.indexOf('faxing4') > -1) {
          myindex = 3
        }
        if (this.saveFaxing.indexOf('faxing5') > -1) {
          myindex = 4
        }
        if (this.saveFaxing.indexOf('faxing6') > -1) {
          myindex = 5
        }
        if (this.saveFaxing.indexOf('faxing7') > -1) {
          myindex = 6
        }
        if (this.saveFaxing.indexOf('faxing8') > -1) {
          myindex = 7
        }
        if (this.saveFaxing.indexOf('faxing9') > -1) {
          myindex = 8
        }
        if (this.saveFaxing.indexOf('faxing10') > -1) {
          myindex = 9
        }
        if (this.saveFaxing.indexOf('faxing11') > -1) {
          myindex = 10
        }
        if (this.saveFaxing.indexOf('faxing12') > -1) {
          myindex = 11
        }
        if (this.saveFaxing.indexOf('faxing13') > -1) {
          myindex = 12
        }
        if (this.saveFaxing.indexOf('faxing14') > -1) {
          myindex = 13
        }
        if (this.saveFaxing.indexOf('faxing15') > -1) {
          myindex = 14
        }
        var hairstyles = this.hairstyles[myindex]
        if (this.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (this.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (this.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (this.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (this.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (this.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        if (this.saveBiaoqing.indexOf('biaoqing7') > -1) {
          eindex = 6
        }
        if (this.saveBiaoqing.indexOf('biaoqing8') > -1) {
          eindex = 7
        }
        var biaoqing = this.biaoqinglist[eindex]
        // alert(hairstyles)
        // alert(background_description)
        const params = {
          base_image_url: this.base_image_url,
          mask_image_url: this.mask_image_url,
          predefined_face_id: predefined_face_id,
          background_image_url: background_image_url,
          face_image_url: face_image_url,
          model_description: model_description,
          background_description: background_description,
          mycount: this.num,
          hairstyle_description: hairstyles,
          aspect_ratio: this.radio,
          biaoqing: biaoqing
        };

        const res = await apiClient.get(`/getrentaitu1`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        console.log(JSON.stringify(res.data))
        var t1 = setInterval(async function () {

          var taskid = res.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          console.log(status12)
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("人台图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.vpath1 = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('2')


            // loading.close();
            that.isLoadingXiaoguo = false;
          }
        }, 8000)



      }
      if (this.currentMenu === '4') {
        // 商品图
        var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
        if (this.base_image_url == '') {
          this.$message.error('请上传商品图片～');
          return false
        }
        if (this.mask_image_url == '') {
          this.$message.error('商品区域图片不能为空～');
          return false
        }

        if (this.saveChangjing == '') {
          this.$message.error('场景图片不能为空～');
          return false
        }
        if (this.prompts == '') {
          this.$message.error('提示词不能为空～');
          return false
        }
        // var loading = this.$loading({
        //   lock: true,
        //   text: "正在完成商品图，请稍等~",
        //   // spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.2)"
        // });
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        const response3 = await apiClient.get('/upload-image', {

          params: {
            imageUrl: this.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url
        const response = await apiClient.get('/generate-background', {
          params: {
            baseImageUrl: this.mask_image_url,
            refImageUrl: background_image_url,
            refPrompt: this.prompts,

            n: 1,
            noiseLevel: 350,
            refPromptWeight: 0.3,
            sceneType: 'COSMETIC'
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var backgrounds = response.data;
        var t1 = setInterval(async function () {

          var taskid = response.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          console.log(status12)
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("商品图生成失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.vpath1 = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,

              }
            });
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('5')


            // loading.close();
            that.isLoadingXiaoguo = false;
          }
        }, 8000)

      }
      if (this.currentMenu === '6') {
        var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
        // 鞋子上脚
        if (this.mask_image_url == '') {
          this.$message.error('鞋子图片不能为空～');
          return false
        }
        if (this.saveMote === '' && this.moteurl === '') {
          this.$message.error('模特图片不能为空～');
          return false
        }
        if (this.moteurl !== '') {
          this.saveMote = this.moteurl;
        }

        var that = this;
        // var loading = this.$loading({
        //   lock: true,
        //   text: "正在处理随机肖像稍等~",
        //   
        var face_image_url = ''

        // var loading = this.$loading({
        //   lock: true,
        //   text: "正在处理随机肖像稍等~",
        //   // spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.2)"
        // });
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        //随机模特
        const response = await apiClient.get('/upload-image', {

          params: {
            imageUrl: this.saveMote
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        face_image_url = response.data.url
        // loading.close()
        this.isLoadingXiaoguo = false;
        // var loading = this.$loading({
        //   lock: true,
        //   text: "正在完成AI换鞋，请稍等~",
        //   // spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.2)"
        // });
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        const params = {
          template_image_url: face_image_url,
          shoe_image_url: this.mask_image_url
        };

        const res = await apiClient.get(`/submitJob`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        var t1 = setInterval(async function () {

          var taskid = res.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          console.log(status12)
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("换鞋失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.vpath1 = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: vpath,
                username: localStorage.username,

              }
            });
            that.miaoshuUrl = ''
            that.koutulist = []
            that.koujian('6')


            // loading.close();
            that.isLoadingXiaoguo = false;
          }
        }, 8000)



      }
    },
    // 删除图片
    handleRemoveImg(file, fileList) {
      this.updateFileList(fileList);
      // 先对比删除的file是否为当前展示的
      if (file.url === this.uploadFile) {
        this.updateUploadUrl();
      }
    },
    // file-list切换图片
    handleImgList(file) {
      this.updateUploadUrl(file);
      const listItems = document.querySelectorAll('.el-upload-list__item');
      listItems.forEach(it => {
        if (it.innerHTML.includes(file.url)) {
          // 选中的为蓝色边框
          it.style.border = '1px solid #2352d8';
        } else {
          // 未选中的默认边框
          it.style.border = '1px solid #c0ccda';
        }
      })
    },
    // 上传图片
    handleUpload(response, file, fileList) {
      // alert(file)
      var mypath = response.filePath
      var mypath = mypath.replace('output\\', '')
      var epath = 'http://www.chenfuzhizao.com.cn/' + mypath
      this.moteurl = epath
      this.uploadUrl = ''
      this.vpath = ''
      this.vpath1 = ''
      this.koutulist = []
      this.updateUploadUrl(file, epath);
      this.updateFileList(fileList);
      setTimeout(() => {
        const listItems = document.querySelectorAll('.el-upload-list__item');
        listItems.forEach(it => {
          if (it.innerHTML.includes(file.url)) {
            // 选中的为蓝色边框
            it.style.border = '1px solid #2352d8';
          } else {
            // 未选中的默认边框
            it.style.border = '1px solid #c0ccda';
          }
        })
      }, 200)
    },
    getTimestampedFilename() {
      const timestamp = new Date().toISOString().replace(/[-:.T]/g, '');
      return `${timestamp}.png`;
    },
    async updateUploadUrl(file, epath) {
      switch (this.currentMenu) {
        case '2':
          //开始抠图
          // alert(epath)
          //开始扣人台图图片
          this.uploadUrl = epath;
          this.miaoshuUrl = epath
          this.koutu3()


          break;
        case '3':
          this.uploadUrl = epath;
          this.miaoshuUrl = epath
          this.koutu3()

          break;
        case '4':
          this.uploadUrl = epath;
          this.miaoshuUrl = epath
          this.koutu4()
          break;
        case '5':
          this.uploadFile4 = file ? file.url : '';
          this.uploadUrl4 = file ? URL.createObjectURL(file.raw) : '';
          break;
        case '6':
          this.uploadUrl = epath;
          this.miaoshuUrl = epath
          this.koutu4()
          break;
      }
    },
    updateFileList(fileList) {
      switch (this.currentMenu) {
        case '2':
          this.fileList1 = fileList;
          break;
        case '3':
          this.fileList2 = fileList;
          break;
        case '4':
          this.fileList3 = fileList;
          break;
        case '5':
          this.fileList4 = fileList;
          break;
      }
    },
    selectRadio(value) {
      this.radio = value;
    },
    childSelected(value) {
      // alert(value)
      this.currentSelected = value;
      if (this.currentGroup === '1') {
        this.saveMote = value;
      }
      if (this.currentGroup === '2') {
        this.saveMote = value;
        var eindex = 0
        // alert(this.saveMote)
        if (this.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (this.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (this.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (this.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (this.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (this.saveMote.indexOf('p810524') > -1) {
          eindex = 5

        }
        var tx = this.defaulttx[eindex]
        // alert(tx)
      }
      if (this.currentGroup === '3') {
        this.saveFaxing = value;
      }
      if (this.currentGroup === '4') {
        this.saveBiaoqing = value;
        // alert(value)


        var eindex = 0
        // alert(this.saveMote)
        if (this.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (this.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (this.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (this.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (this.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (this.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        var biaoqing = this.biaoqinglist[eindex]
        // alert(biaoqing)
      }
      if (this.currentGroup === '5' || this.currentGroup === '6' || this.currentGroup === '7') {
        this.saveChangjing = value;

        var eindex = 0
        // alert(this.saveMote)
        if (this.saveChangjing.indexOf('changjing1') > -1) {
          eindex = 0
        }
        if (this.saveChangjing.indexOf('changjing2') > -1) {
          eindex = 1
        }
        if (this.saveChangjing.indexOf('changjing3') > -1) {
          eindex = 2
        }
        if (this.saveChangjing.indexOf('changjing4') > -1) {
          eindex = 3
        }
        if (this.saveChangjing.indexOf('changjing5') > -1) {
          eindex = 4
        }
        if (this.saveChangjing.indexOf('changjing6') > -1) {
          eindex = 5
        }
        if (this.saveChangjing.indexOf('changjing7') > -1) {
          eindex = 6

        }
        var changjing = this.changjinglist[eindex]


      }
    },
    handleMore(group) {
      this.currentGroup = group;
      // 处理依次点击更多时不能关闭的BUG
      this.closeAllMore();
      if (group === '1') {
        this.imgMoreVisible = true;
        this.currentSelected = this.saveMote;
        this.moreTitle = {
          bigTitle: '随机模特',
          remark: '（模特面部固定不变，适合在生成多图时需要面部保持一致的情况下使用）',
          name: ['']
        }
      }
      if (group === '2') {
        this.imgMoreVisible2 = true;
        this.currentSelected = this.saveMote;
        this.moreTitle = {
          bigTitle: '固定模特',
          remark: '（面部根据所选类型随机生成，适合对人物面部多样性有要求的情况下使用）',
          name: ['青年']
        }
      }
      if (group === '3') {
        this.imgMoreVisible3 = true;
        this.currentSelected = this.saveFaxing;
        this.moreTitle = {
          bigTitle: '发型',
          remark: '',
          name: ['']
        }
      }
      if (group === '4') {
        this.imgMoreVisible4 = true;
        this.currentSelected = this.saveBiaoqing;
        this.moreTitle = {
          bigTitle: '表情',
          remark: '',
          name: []
        }
      }
      if (group === '5') {
        this.imgMoreVisible5 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: '场景',
          remark: '',
          name: []
        }
      }
      // 商品图场景
      if (group === '6') {
        this.imgMoreVisible6 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: '固定场景',
          remark: '（场景构图不变，适合在生成多图时需要场景保持一致的情况下使用）',
          name: ['自然']
        }
      }
      if (group === '7') {
        this.imgMoreVisible7 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: '随机场景',
          remark: '（场景根据所选风格随机生成，每次生成的背景都不一致）',
          name: ['自然']
        }
      }
    },
    handleCloseMore() {
      if (this.currentGroup === '1') {
        this.imgMoreVisible = false;
      }
      if (this.currentGroup === '2') {
        this.imgMoreVisible2 = false;
      }
      if (this.currentGroup === '3') {
        this.imgMoreVisible3 = false;
      }
      if (this.currentGroup === '4') {
        this.imgMoreVisible4 = false;
      }
      if (this.currentGroup === '5') {
        this.imgMoreVisible5 = false;
      }
      if (this.currentGroup === '6') {
        this.imgMoreVisible6 = false;
      }
      if (this.currentGroup === '7') {
        this.imgMoreVisible7 = false;
      }
    },
    closeAllMore() {
      this.imgMoreVisible = false;
      this.imgMoreVisible2 = false;
      this.imgMoreVisible3 = false;
      this.imgMoreVisible4 = false;
      this.imgMoreVisible5 = false;
      this.imgMoreVisible6 = false;
      this.imgMoreVisible7 = false;
    },
    handleImage(group, item) {

      this.currentSelected = item;
      if (group === '1' || group === '2') {

        this.saveMote = item;
      }
      if (group === '3') {

        this.saveFaxing = item;
      }
      if (group === '4') {
        this.saveBiaoqing = item;
      }
      if (group === '5' || group === '6' || group === '7') {
        this.saveChangjing = item;
      }
    },
    async loadingdata1() {

      const response = await apiClient.get(`/getmodels6`, {
        params: {
          page: 1,
          limit: 44,
          continent: '',
          gender: '',
          leixing: '青年'
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      this.totalImagesCount += mylist.length;
      this.imgList1[0] = []
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = 'http://www.chenfuzhizao.com.cn/';
        let contentpaht = item.image_path;
        contentpaht = contentpaht.replace("./output", "");
        let path1 = path + contentpaht;
        item.image_path = path1;
        this.imgList1[0].push(path1)
      }
      //获取儿童图片
      // var response1 = await apiClient.get(`/getmodels1`, {
      //   params: {
      //     page: 1,
      //     limit: 44,
      //     continent: '',
      //     gender: '',
      //     leixing: '儿童'
      //   },
      //   headers: {
      //     'x-access-token': localStorage.token
      //   },
      // });
      // const mylist1 = response1.data.models;
      // this.totalImagesCount += mylist1.length;
      // this.imgList1[1] = []
      // for (let i = 0; i < mylist1.length; i++) {
      //   let item = mylist1[i];
      //   let path = 'http://www.chenfuzhizao.com.cn/';
      //   let contentpaht = item.image_path;
      //   contentpaht = contentpaht.replace("./output", "");
      //   let path1 = path + contentpaht;
      //   item.image_path = path1;
      //   this.imgList1[1].push(path1)
      // }
    },
    async loadingdata() {

      const response = await apiClient.get(`/getmodels5`, {
        params: {
          page: 1,
          limit: 44,
          continent: '',
          gender: '',
          leixing: '青年'
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      this.totalImagesCount += mylist.length;
      this.imgList1[0] = []
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = 'http://www.chenfuzhizao.com.cn/';
        let contentpaht = item.image_path;
        contentpaht = contentpaht.replace("./output", "");
        let path1 = path + contentpaht;
        item.image_path = path1;
        this.imgList1[0].push(path1)
      }
      //获取儿童图片
      // var response1 = await apiClient.get(`/getmodels1`, {
      //   params: {
      //     page: 1,
      //     limit: 44,
      //     continent: '',
      //     gender: '',
      //     leixing: '儿童'
      //   },
      //   headers: {
      //     'x-access-token': localStorage.token
      //   },
      // });
      // const mylist1 = response1.data.models;
      // this.totalImagesCount += mylist1.length;
      // this.imgList1[1] = []
      // for (let i = 0; i < mylist1.length; i++) {
      //   let item = mylist1[i];
      //   let path = 'http://www.chenfuzhizao.com.cn/';
      //   let contentpaht = item.image_path;
      //   contentpaht = contentpaht.replace("./output", "");
      //   let path1 = path + contentpaht;
      //   item.image_path = path1;
      //   this.imgList1[1].push(path1)
      // }
    },
    selectMenu(index) {
      this.vpath1 = ''
      this.miaoshuUrl = ''
      this.isLoadingXiaoguo = false
      this.uploadUrl = ''
      this.koutulist = []
      this.base64Image=''
      this.moteurl = 'http://www.chenfuzhizao.com.cn/moren/weishangchuan.png'
      // alert(index)

      this.currentMenu = index;
      if (index == 2 || index == 3) {
        this.loadingdata()
      }
      if (index == 6) {
        this.loadingdata1()
      }
      this.closeAllMore();
      setTimeout(() => {
        const listItems = document.querySelectorAll('.el-upload-list__item');
        listItems.forEach(it => {
          if (it.innerHTML.includes(this.uploadFile)) {
            // 选中的为蓝色边框
            it.style.border = '1px solid #2352d8';
          } else {
            // 未选中的默认边框
            it.style.border = '1px solid #c0ccda';
          }
        })
      }, 200)
    },
    selectedBackshow(value) {
      if (value) {
        this.saveMote = value.mote || '';
        this.saveFaxing = value.faxing || '';
        this.saveBiaoqing = value.biaoqing || '';
        this.saveChangjing = value.changjing || '';
        this.num = value.num || 1;
        this.radio = value.bili || '比例不变';
      }
    },
    checkAllResourcesLoaded() {
      // 在这里可以添加更多判断条件
      // 如图片加载、异步数据请求等
      this.allResourcesLoaded = true;
      this.myloading.close()
    },
    zoomImage() {
      this.imgPreviewVisible = true;
      this.scalePercent = 100;
      setTimeout(() => {
        const panzoomElement = this.$refs.panzoomElement;
        // 初始化 Panzoom 实例
        this.panzoomInstance = Panzoom(panzoomElement, {
          maxScale: 3,
          minScale: 0.5,
        });
        // panzoomElement.addEventListener('wheel', this.panzoomInstance.zoomWithWheel);
      }, 1000)
    },
    async downloadImage() {
      const response = await axios.get(this.vpath1, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.png'); // Specify the file name
      document.body.appendChild(link);
      link.click();
    },
    async downloadImage1() {
      const response = await axios.get(this.vpath, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.png'); // Specify the file name
      document.body.appendChild(link);
      link.click();
    },
    edit2() {
      this.editurl = this.dpath
      this.dialogVisible4 = true
    },

    edit1() {
      this.editurl = this.moteurl
      this.dialogVisible4 = true
    },
    async koujian(mystyle) {
      // alert(JSON.stringify(response))
      const response = await apiClient.get('/insertxiaofei', {
        params: {
          userId: localStorage.username,
          type: 'image_change',
          details: 'AI换装',
          mystyle: mystyle
        },
        headers: {
          'x-access-token': localStorage.token
        },

      });
    },
    async huianyi1() {
      this.vpath1 = ''
      var that = this
      if (this.outputPath.length == 0) {
        this.$message.error("请上传连体衣服正照~")
        return false
      }

      if (this.moteurl.length == 0) {
        this.$message.error("请选择模特照片~")
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 10) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在完成AI换装~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      this.vpath = ''
      this.vpath1 = ''
      //AI全身换装
      var response = await apiClient.get('/ai-fitting', {
        params: {
          topGarmentUrl: this.outputPath,

          personImageUrl: this.moteurl,

        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      var t1 = setInterval(async function () {
        console.log(JSON.stringify(response.data))
        var taskid = response.data.output.task_id
        var tresponse1 = await apiClient.get('/gettask', {
          params: {
            taskId: taskid,

          }
        });
        console.log(JSON.stringify(tresponse1.data))
        var status12 = tresponse1.data.status12.task_status
        console.log(status12)
        if (status12 == 'FAILED') {
          clearInterval(t1)
          console.log(imgurl1)
          // loading.close();
          that.isLoadingXiaoguo = false;
          that.$message.error("换装失败模特图片或者衣服图片不符合转装要求~")
        }
        if (status12 == 'SUCCEEDED') {
          clearInterval(t1)
          var imgurl1 = tresponse1.data.status12.image_url
          var response2 = await apiClient.get('/downloadmyurl', {
            params: {
              url2: imgurl1,

            }
          })
          var path2 = response2.data.outputPath
          var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
          //  alert(vpath)
          that.vpath1 = vpath
          var tresponse1 = await apiClient.get('/inserrlist', {
            params: {
              resulturl: that.vpath1,
              username: localStorage.username,

            }
          });
          that.isLoadingXiaoguo = false;
          that.koujian('1')

          // loading.close();

        }
      }, 8000)


    },
    async huianyi() {
      var that = this
      this.vpath1 = ''
      if (this.outputPath.length == 0) {
        this.$message.error("请上传上半身正照~")
        return false
      }
      if (this.loutputPath.length == 0) {
        this.$message.error("请上传下半身正照~")
        return false
      }
      if (this.moteurl.length == 0 || this.moteurl == null || this.moteurl == 'http://www.chenfuzhizao.com.cn/moren/weishangchuan.png') {
        this.$message.error("请选择模特照片~")
        return false


      }
      console.log(this.moteurl)
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 10) {
        this.$message.error("您的换装套餐已经用完~")
        this.showModal = true
        return false
      }
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在完成AI换装~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';

      this.vpath = ''
      this.vpath1 = ''
      //AI全身换装
      var response = await apiClient.get('/ai-fitting', {
        params: {
          topGarmentUrl: this.outputPath,
          bottomGarmentUrl: this.loutputPath,
          personImageUrl: this.moteurl,

        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      var t1 = setInterval(async function () {
        console.log(JSON.stringify(response.data))
        var taskid = response.data.output.task_id
        var tresponse1 = await apiClient.get('/gettask', {
          params: {
            taskId: taskid,

          }
        });
        console.log(JSON.stringify(tresponse1.data))
        var status12 = tresponse1.data.status12.task_status
        console.log(status12)
        if (status12 == 'FAILED') {
          clearInterval(t1)
          console.log(imgurl1)
          // loading.close();
          that.isLoadingXiaoguo = false;
          that.$message.error("换装失败模特图片或者衣服图片不符合转装要求~")
        }
        if (status12 == 'SUCCEEDED') {
          clearInterval(t1)
          var imgurl1 = tresponse1.data.status12.image_url
          var response2 = await apiClient.get('/downloadmyurl', {
            params: {
              url2: imgurl1,

            }
          })
          var path2 = response2.data.outputPath
          var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
          //  alert(vpath)
          that.vpath1 = vpath
          var tresponse1 = await apiClient.get('/inserrlist', {
            params: {
              resulturl: that.vpath1,
              username: localStorage.username,

            }
          });
          that.isLoadingXiaoguo = false;
          that.koujian('1')

          // loading.close();

        }
      }, 8000)





    },
    queren() {
      this.moteurl = this.dpath
      this.dialogVisible3 = false
    },
    async generateModel() {
      var that = this
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在生成您的模特",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';

      if (this.value.length == 0) {
        this.$message.warning('请选择地区');
        return false
      }
      if (this.value1.length == 0) {
        this.$message.warning('请选择性别');
        return false
      }
      if (this.value2.length == 0) {
        this.$message.warning('请选择年龄');
        return false
      }
      if (this.value3.length == 0) {
        this.$message.warning('请选择笑容');
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].train
      train = parseInt(train)
      if (train < 1) {
        this.$message.error("您的模特训练次数已经用完~")
        this.showModal = true
        return false
      }
      try {
        const response = await apiClient.get('/generateModel1', {
          params: {
            continent: this.value,
            gender: this.value1,
            ageRange: this.value2,
            expression: this.value3
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        // Handle the response as needed, for example, display the image
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {


          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,

            }
          });
          console.log(JSON.stringify(tresponse1.data))
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)

            // loading.close();
            that.isLoadingXiaoguo = false;
            that.$message.error("模特图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,

              }
            })
            var path2 = response2.data.outputPath
            var vpath = 'http://www.chenfuzhizao.com.cn/' + path2.replace('./output/', '')
            //  alert(vpath)
            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,

              }
            });

            // var tresponse2 = await apiClient.get('/savemote', {
            //   params: {
            //     filePath: path2,
            //     username: localStorage.username,

            //   }
            // });
            var tresponse3 = await apiClient.get('/insertmodels12', {
              params: {
                continent: that.value, gender: that.value1, age: that.value2, imagePath: path2, xianshi: '2'
              }
            });
            that.koujian1()
            // loading.close()
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async openbtn2() {
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].train
      train = parseInt(train)
      if (train < 1) {
        this.$message.error("您的模特训练次数已经用完~")
        this.showModal = true
        return false
      }

      this.dialogVisible3 = true
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    async loadMoreModels() {
      if (this.loading) return;
      this.loading = true;
      try {
        const { continent, gender } = this.categoryMap[this.activeName3];

        const response = await apiClient.get(`/models5`, {
          params: {
            page: this.page,
            limit: this.limit,
            continent: continent,
            gender: gender
          }
        });
        const mylist = response.data.models;
        // alert(JSON.stringify(mylist))
        for (let i = 0; i < mylist.length; i++) {
          let item = mylist[i];
          let path = 'http://www.chenfuzhizao.com.cn/';
          let contentpaht = item.image_path;
          contentpaht = contentpaht.replace("./output/", "");
          contentpaht = contentpaht.replace("./output", "");
          let path1 = path + contentpaht;
          mylist[i].image_path = path1;
          mylist[i].selected = '0'
        }

        this.models = [...this.models, ...mylist];
        // alert(JSON.stringify(this.models))
        this.page += 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      console.log('scroll event triggered');


      this.loadMoreModels();

    },
    handleClick3(tab) {
      this.activeName3 = tab.name;
      this.page = 1; // 重置分页
      this.models = []; // 清空当前模型数据
      this.loadMoreModels(); // 重新加载模型数据
    },
    openbtn() {
      // this.loadMoreModels()
      this.dialogVisible1 = true;

    },
    openbtn1() {
      this.mytext = '上传模特库'
      this.dialogVisible2 = true;

    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose1() {
      this.dialogVisible1 = false

    },
    selectModel(index) {
      // 取消之前选中的模型
      for (var i = 0; i < this.uploads.length; i++) {
        this.uploads[i].selected = '0'
      }
      this.uploads[index].selected = '1'
      if (this.selectstyle == '0') {
        this.shangmiantu = this.uploads[index].filePath
        this.koutu()
      }
      else if (this.selectstyle == '1') {
        this.lshangmiantu = this.uploads[index].filePath
        this.koutu1()
      }
      this.dialogVisible = false
      // alert(JSON.stringify(this.uploads))

    },
    selectMode2(index) {
      // 取消之前选中的模型
      for (var i = 0; i < this.uploads.length; i++) {
        this.uploads[i].selected = '0'
      }
      this.uploads[index].selected = '1'
      this.moteurl = this.uploads[index].filePath
      this.dialogVisible2 = false
      // alert(JSON.stringify(this.uploads))

    },
    selectModel1(index) {
      // 取消之前选中的模型
      // alert(index)
      for (var i = 0; i < this.models.length; i++) {
        this.models[i].selected = '0'
      }
      this.models[index].selected = '1'
      this.moteurl = this.models[index].image_path
      this.dialogVisible1 = false
      // alert(JSON.stringify(this.uploads))

    },
    async getUploads1() {
      try {
        const response = await apiClient.get('/getuploads1', {
          headers: {
            'x-access-token': localStorage.token
          },
          params: {
            username: this.username
          }
        });
        var list = response.data.uploads
        for (var i = 0; i < list.length; i++) {
          var item = list[i]
          var path = item.filePath
          var mypath = path.replace('output\\', '')
          mypath = mypath.replace('./output/', '')
          // alert(mypath)
          var epath = 'http://www.chenfuzhizao.com.cn/' + mypath
          list[i].filePath = epath
          list[i].selected = '0'
        }
        this.uploads = list;

      } catch (error) {
        console.error('Error fetching uploads:', error);
        this.uploads = [];
      }
    },
    async getUploads() {
      try {
        const response = await apiClient.get('/getuploads', {
          headers: {
            'x-access-token': localStorage.token
          },
          params: {
            username: this.username
          }
        });
        var list = response.data.uploads
        for (var i = 0; i < list.length; i++) {
          var item = list[i]
          var path = item.filePath
          var mypath = path.replace('output\\', '')
          var epath = 'http://www.chenfuzhizao.com.cn/' + mypath
          list[i].filePath = epath
          list[i].selected = '0'
        }
        this.uploads = list;

      } catch (error) {
        console.error('Error fetching uploads:', error);
        this.uploads = [];
      }
    },
    chengong(response, file, fileList) {
      var mypath = response.filePath
      // alert(mypath)
      var mypath = mypath.replace('output\\', '')
      var epath = 'http://www.chenfuzhizao.com.cn/' + mypath
      this.dialogVisible = false
      if (this.selectstyle == '0') {
        this.shangmiantu = epath
        this.koutu()
      }
      else {
        this.lshangmiantu = epath
        this.koutu1()
      }

    },
    chengong1(response, file, fileList) {
      var mypath = response.filePath
      // alert(mypath)
      var mypath = mypath.replace('output\\', '')
      var epath = 'http://www.chenfuzhizao.com.cn/' + mypath
      this.moteurl = epath
      this.dialogVisible2 = false


    },
    upload1(style) {
      this.dialogVisible = true
      this.selectstyle = style
      if (this.selectstyle == '0') {
        this.dialogtitle = '上传上身正面图'
        this.mytext = '上传上身正面图'
        this.furl1 = this.url1
      }
      else if (this.selectstyle == '1') {
        this.dialogtitle = '上传下身正面图'
        this.mytext = '上传下身正面图'
        this.furl1 = this.lurl1
      }
    },
    // btn_shangshen1(index) {
    //   this.shangmiantu = ''
    //   this.outputPath = ''
    //   if (index == '0') {
    //     this.shangmiantu = this.lturl1
    //   }
    //   else if (index == '1') {
    //     this.shangmiantu = this.lturl2
    //   }
    //   else if (index == '2') {
    //     this.shangmiantu = this.lturl3
    //   }
    //   else if (index == '3') {
    //     this.shangmiantu = this.lturl4
    //   }
    //   this.koutu()
    // },
    // btn_shangshen(index) {
    //   this.shangmiantu = ''
    //   this.outputPath = ''
    //   if (index == '0') {
    //     this.shangmiantu = this.url1
    //   }
    //   else if (index == '1') {
    //     this.shangmiantu = this.url2
    //   }
    //   else if (index == '2') {
    //     this.shangmiantu = this.url3
    //   }
    //   else if (index == '3') {
    //     this.shangmiantu = this.url4
    //   }
    //   this.koutu()
    // },
    // lbtn_shangshen(index) {
    //   this.lshangmiantu = ''
    //   this.loutputPath = ''
    //   if (index == '0') {
    //     this.lshangmiantu = this.lurl1
    //   }
    //   else if (index == '1') {
    //     this.lshangmiantu = this.lurl2
    //   }
    //   else if (index == '2') {
    //     this.lshangmiantu = this.lurl3
    //   }
    //   else if (index == '3') {
    //     this.lshangmiantu = this.lurl4
    //   }
    //   this.koutu1(this.lshangmiantu)
    // },

    handleClick(tab, event) {
      this.activeName = tab.name

      //  alert(tab.name)
    },
    handleClick1(tab, event) {
      this.activeName1 = tab.name
      // alert(tab.name)
      if (tab.name == 'second') {
        this.getUploads()
      }
      //  alert(tab.name)
    },
    ehandleClick1(tab, event) {
      this.activeName1 = tab.name
      // alert(tab.name)
      if (tab.name == 'second') {
        this.getUploads1()
      }
      //  alert(tab.name)
    },
    async koujian1() {
      // alert(JSON.stringify(response))
      const response = await apiClient.get('/insertxiaofei', {
        params: {
          userId: localStorage.username,
          type: 'model_usage',
          details: '模特训练'
        },
        headers: {
          'x-access-token': localStorage.token
        },

      });
    },
    async processImage1() {
      var loading = this.$loading({
        lock: true,
        text: "正在抠图中~",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
      });
      try {

        const response = await apiClient.get('/process-image', {

          params: {
            imageUrl: this.lshangmiantu
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var outputPath = response.data.imagePath
        this.loutputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
        loading.close()

        // alert(this.outputPath)
      } catch (error) {
        loading.close()
        console.error('Error processing image:', error);
        // alert('Error processing image');
      }
    },
    async koutu1() {
      //完成抠图
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在抠图中~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.lshangmiantu
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      // alert(myurl1)
      //开始抠
      const response1 = await apiClient.get('/segment-cloth', {

        params: {
          imageURL: myurl1,
          clothClass: 'pants,skirt'
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.loutputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")

      // loading.close()


    },
    async koutu5() {
      //抠上衣
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在抠图中~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.miaoshuUrl
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      this.base_image_url = myurl1
      // alert(this.base_image_url)
      var g1 = 'tops'
      const response1 = await apiClient.get('/segment-cloth3', {

        params: {
          imageURL: myurl1,

        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.outputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      var outpath = this.outputPath
      this.koutulist.push({ outpath, "ischeck": "0" })
      const response2 = await apiClient.get('/upload-image', {

        params: {
          imageUrl: outpath
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      this.mask_image_url = response2.data.url
      // alert(this.mask_image_url)


      // alert(myurl1)
      //开始抠
      //   const response2 = await apiClient.get('/segment-cloth', {

      //     params: {
      //       imageURL: myurl1,
      //       clothClass: 'pants'
      //     },
      //     headers: {
      //       'x-access-token': localStorage.token
      //     }
      //   });
      //   var outputPath = response2.data.outputPath
      //   this.loutputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      //   var outpath=this.loutputPath
      //  this.koutulist.push({ outpath, "ischeck": "0" })
      // loading.close()
      this.isLoadingXiaoguo = false;

    },
    async koutu4() {
      //抠上衣
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在抠图中~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.miaoshuUrl
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      this.base_image_url = myurl1
      // alert(this.base_image_url)
      var g1 = 'tops'
      const response1 = await apiClient.get('/segment-cloth2', {

        params: {
          imageURL: myurl1,
          clothClass: g1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.outputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      var outpath = this.outputPath
      this.koutulist.push({ outpath, "ischeck": "0" })
      const response2 = await apiClient.get('/upload-image', {

        params: {
          imageUrl: outpath
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      this.mask_image_url = response2.data.url
      // alert(this.mask_image_url)


      // alert(myurl1)
      //开始抠
      //   const response2 = await apiClient.get('/segment-cloth', {

      //     params: {
      //       imageURL: myurl1,
      //       clothClass: 'pants'
      //     },
      //     headers: {
      //       'x-access-token': localStorage.token
      //     }
      //   });
      //   var outputPath = response2.data.outputPath
      //   this.loutputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      //   var outpath=this.loutputPath
      //  this.koutulist.push({ outpath, "ischeck": "0" })
      // loading.close()
      this.isLoadingXiaoguo = false;

    },
    async koutu3() {
      //抠上衣
      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在抠图中~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      this.isLoadingXiaoguo = true;
      this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.miaoshuUrl
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      this.base_image_url = myurl1
      // alert(this.base_image_url)
      var g1 = 'tops'
      const response1 = await apiClient.get('/segment-cloth1', {

        params: {
          imageURL: myurl1,
          clothClass: g1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.outputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      var outpath = this.outputPath
      this.koutulist.push({ outpath, "ischeck": "0" })
      const response2 = await apiClient.get('/upload-image', {

        params: {
          imageUrl: outpath
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      this.mask_image_url = response2.data.url
      // alert(this.mask_image_url)


      // alert(myurl1)
      //开始抠
      //   const response2 = await apiClient.get('/segment-cloth', {

      //     params: {
      //       imageURL: myurl1,
      //       clothClass: 'pants'
      //     },
      //     headers: {
      //       'x-access-token': localStorage.token
      //     }
      //   });
      //   var outputPath = response2.data.outputPath
      //   this.loutputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")
      //   var outpath=this.loutputPath
      //  this.koutulist.push({ outpath, "ischeck": "0" })
      // loading.close()
      this.isLoadingXiaoguo = false;

    },
    async koutu() {
      //完成抠图

      // var loading = this.$loading({
      //   lock: true,
      //   text: "正在抠图中~",
      //   // spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)"
      // });
      var g1 = 'tops'
      if (this.activeName == 'second') {
        g1 = 'tops,coat,skirt,pants'
      }
      // alert(g1)
      const response = await apiClient.get('/upload-image', {

        params: {
          imageUrl: this.shangmiantu
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var myurl1 = response.data.url
      // alert(myurl1)
      //开始抠
      const response1 = await apiClient.get('/segment-cloth', {

        params: {
          imageURL: myurl1,
          clothClass: g1
        },
        headers: {
          'x-access-token': localStorage.token
        }
      });
      var outputPath = response1.data.outputPath
      this.outputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")

      // loading.close()


    },
    async koutu12() {

    },
    async processImage() {
      var loading = this.$loading({
        lock: true,
        text: "正在抠图中~",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
      });
      try {

        const response = await apiClient.get('/process-image', {

          params: {
            imageUrl: this.shangmiantu
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var outputPath = response.data.imagePath
        this.outputPath = 'http://www.chenfuzhizao.com.cn/' + outputPath.replace("./output", "")

        loading.close()
        // alert(this.outputPath)
      } catch (error) {
        loading.close()
        console.error('Error processing image:', error);
        // alert('Error processing image');
      }
    }


  }
}
</script>

<style scoped>
.image-container {
  display: flex;
  /* 使用Flexbox布局 */
  align-items: center;
  /* 垂直居中 */
  overflow-x: auto;
  /* 水平滚动 */
  white-space: nowrap;
  /* 防止图片换行 */
}

.image-container img {
  max-width: 100%;
  /* 图片最大宽度为容器宽度，保证不失真 */
  height: auto;
  /* 高度自动 */
  flex: 0 0 auto;
  /* 不伸缩 */
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  height: 90px;
}

.model-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
  scrollbar-width: none;
  /* For Firefox */
  z-index: 99999999;
}

.model-gallery::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.model-card {
  width: 200px;
  text-align: center;
}

.model-card img {
  width: 100%;
  border-radius: 10px;
}

.loader {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.upload-demo {
  max-width: 100%;
}

.wenzi {
  bottom: -20px;
  color: #fff;
  background-color: #00000080;
  box-shadow: 0 1px 2px #0000001f;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 0 25px;
  font-size: 12px;
  z-index: 10;
}

.container {
  display: flex;
  height: calc(100vh - 62px);
  /* Full viewport height */
  background-color: white;
  /* height: calc(200vh - 500px);  */
}

.box {
  background-color: white;
  border: 1px solid rgba(5, 5, 5, 0.06);
  /* Optional: to visually separate the boxes */
  height: 100%;
  overflow-y: scrollss;
  /* scrollbar-width: none; */
  /* Ensure the boxes take full height of the container */
}

.selpage {
  position: relative;
  margin-top: 10px;
  float: left;
  margin-left: 0px;
  width: 100%;
  height: auto;
}

.selpage1 {
  position: relative;
  margin-top: 10px;
  float: left;
  margin-left: 0px;
  width: 100%;
  height: auto;
}

.upload_ele /deep/.el-upload-dragger {
  width: 260px;
  height: 150px;
}

.more {
  position: relative;
  width: 87px;
  height: 87px;
}

.more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  width: 87px;
  height: 87px;
  cursor: pointer;
}

.image {
  width: 87px;
  height: 87px;
  box-sizing: border-box;
  border-radius: 4px;
}

.image /deep/.el-image__inner {
  width: 100%;
  height: auto;
}

.imgtext {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  padding: 2px 0;
}

.image :hover {
  cursor: pointer;
}

.selected {
  /* #1890ff */
  border: 2px solid #2352d8;
  padding: 1px;
}

.imgcol {
  margin-bottom: 5px;
}

.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}

.generatenum /deep/ .el-input__inner {
  text-align: center;
}

.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}

.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  /* background-color: #2352d8; */
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}

.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}

.xiaoguodiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.xiaoguotu {
  position: relative;
  border: 2px dashed rgba(4, 17, 51, 0.26);
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(4, 17, 51, 0.45);
  padding: 10px 0;
}

.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}

.radioSelected {
  border: 1px solid #2352d8;
}

.el-button.is-plain:hover {
  color: #2352d8;
}

.el-button.is-plain:focus {
  border: 1px solid #2352d8;
  color: #2352d8;
}

.showImgdiv {
  width: 50%;
  height: 80%;
  border: 1px solid rgba(4, 17, 51, 0.26);
  padding: 2px;
  box-sizing: border-box;
  border-radius: 10px;
}

.showImgdiv .img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.menuicon {
  width: 15px;
  height: auto;
  margin: 0 10px 0 5px;
}

.xiaohaodiv {
  display: flex;
  align-items: center;
}

.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}

.image_container {
  position: relative;
  display: inline-block;
}

.image_container img {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  /* 蒙层的半透明背景 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  /* 初始状态透明 */
  transition: opacity 0.3s ease;
  border-radius: 10px
}

.botton_icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.botton_icon img {
  cursor: pointer;
}

.image_container:hover .overlay {
  opacity: 1;
  /* 鼠标悬停时显示蒙层 */
}

.previewdialog /deep/ .el-dialog {
  margin-top: 5vh !important;
}

.previewdialog /deep/ .el-dialog__body {
  height: 70vh !important;
}

.previewdialog /deep/ .dialog-footer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewdiv {
  height: 100%;
  width: 100%;
}

.previewdiv img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loading_overlay i {
  font-size: 50px;
}

.sizeicon {
  width: 160px;
  height: 35px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  right: 20px;
}
</style>
