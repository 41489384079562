
<template>
  <div>
    <div class="home">
      <Hero />
      <div class="features">
        <!-- <FeatureCard
          v-for="(feature, index) in features"
          :key="index"
          :image="feature.image"
          :title="feature.title"
          :description="feature.description"
        /> -->
      </div>
      <div class="motetitle">
        <div class="content1">
          海量模特随时使用
        </div>
        <div class="content2">
          涵盖不同人种、年龄、性别、风格的模特库，满足客户的审美要求。
        </div>
      </div>
      <ModelGallery />
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Footer from '@/components/Footer.vue';
// import FeatureCard from '@/components/FeatureCard.vue';
import ModelGallery from '@/components/ModelGallery.vue';
export default {
  name: 'Home',
  components: {
    Hero,
    // FeatureCard,
    ModelGallery,
    Footer
  },
  data() {
    return {
      features: [
        {
          image: 'https://via.placeholder.com/100',
          title: '亚洲模特',
         
        },
        {
          image: 'https://via.placeholder.com/100',
          title: '美洲模特',
        
        },
        {
          image: 'https://via.placeholder.com/100',
          title: '欧洲模特',
         
        },
        {
          image: 'https://via.placeholder.com/100',
          title: '非洲模特',
         
        },
        {
          image: 'https://via.placeholder.com/100',
          title: '东南亚模特',
         
        }
      ]
    };
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'youshe';
  src: url('../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.home {
  /* padding: 20px; */
}
.features {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.footer{
  background-color: #000;
  color: #fff;
  display: flex;
  /* bottom: 0px; */
  /* position: fixed; */
  border-bottom: 1px solid #333;
  width: 100%;
}
.motetitle {
  width: 100%;
  text-align: center;
}
.content1 {
  font-family: 'youshe';
  font-size: 40px;
}
</style>
    