<template>
    <el-dialog title="隐私政策" :visible.sync="visible" width="60%" :modal="false" :before-close="handleClose">
        <div class="privacy-policy-content" v-html="xieyi">
         
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import apiClient from '@/services/api';
export default {
    name: 'PrivacyPolicy',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
          xieyi:''
        };
    },
    watch: {
        visible(newVal) {
            if (!newVal) {

            }
            this.initdata()
        }
    },
    methods: {
        async initdata() {
            const response3 = await apiClient.get('/getxieyi', {});
            this.xieyi=response3.data.data[0].xieyi
            
        },
        handleClose() {
            this.$emit('update:visible', false);
        }
    }
}
</script>

<style scoped>
.privacy-policy-content {
    max-height: 400px;
    overflow-y: auto;
    padding: 0 20px;
}
</style>